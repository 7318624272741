import React from 'react'
import {PureComponent} from 'react'
import ImmutableSelect from '../../../../components/ImmutableSelect'
import InputComponent from '../../../../components/Input'
import TimePicker from '../../../../components/TimePicker'
import {CSSTransition, TransitionGroup} from 'react-transition-group'

import _ from 'lodash'
import classNames from 'classnames'
import {getFieldError} from '../../../../util'
import {List} from 'immutable'

import Reminder from '../../../../models/Reminder'
import ReminderReceiver from '../../../../models/ReminderReceiver'
import StudyGroups from '../../../../models/StudyGroups'
import User from '../../../../models/User'
import Users from '../../../../models/Users'

interface Props {
  groups: StudyGroups
  user: User
  users: Users
  sendCallback: (reminder, pathComponents?, queryParams?) => any
  sendNowCallback: (reminder) => any
  t: (key, params?) => any
}

interface State {
  reminder: Reminder
  open: boolean
}

export default class AddReminderBox extends PureComponent<Props, State> {
  constructor(props: Props) {
    super(props)
    this.state = {
      open: true,
      reminder: this.createNewReminder()
    }
  }

  componentDidUpdate() {
    const {user} = this.props

    if (user.selectedSiteStudyId !== this.state.reminder.get('studySiteId')) {
      this.setState({reminder: this.createNewReminder()})
    }
  }

  createNewReminder = () => {
    return new Reminder({
      sendTime: new Date(),
      studySiteId: this.props.user.selectedSiteStudyId
    })
  }

  onSelectedGroupsChange = selectedGroups => {
    const {reminder} = this.state
    const selectedPersons = reminder.receivers.filter(({groupId}) => !groupId)
    const {groups} = this.props

    const selectedGroupPersons = _.map(selectedGroups, groupId => {
      const group = groups.getModelById(groupId)

      return List(
        group.userIds.map(function(userId) {
          return new ReminderReceiver({
            reminderId: reminder.id,
            personId: userId,
            groupId: groupId
          })
        })
      )
    }).reduce((accu, list) => accu.concat(list), List())

    this.setState({
      reminder: reminder.set('receivers', selectedPersons.concat(selectedGroupPersons)) as Reminder
    })
  }

  onSelectedUsersChange = selectedUsers => {
    const {reminder} = this.state
    const selectedGroupPersons = reminder.receivers.filter(receiver => !!receiver.groupId)

    const selectedPersons = List(
      _.map(selectedUsers, userId => {
        return new ReminderReceiver({
          reminderId: reminder.id,
          personId: userId
        })
      })
    )

    this.setState({
      reminder: reminder.set('receivers', selectedPersons.concat(selectedGroupPersons)) as Reminder
    })
  }

  handleMessageChange = value =>
    this.setState({
      reminder: this.state.reminder.set('message', value) as Reminder
    })

  uniqueOptions = list => List(_.compact(_.uniq(list.toArray())))

  getSelectedOptions = fieldName => {
    const {reminder} = this.state

    if (fieldName === 'users') {
      return this.uniqueOptions(
        reminder.receivers.filter(({groupId}) => !groupId).map(({personId}) => personId)
      )
    } else {
      return this.uniqueOptions(
        reminder.receivers.filter(({groupId}) => !!groupId).map(({groupId}) => groupId)
      )
    }
  }

  createSelectCriteria = (fieldName, label, onChange) => {

    const {t} = this.props

    return (
      <div key={fieldName + '_group'} className='form-group'>
        <label className='col-xs-3 col-sm-2 control-label' htmlFor={fieldName}>
          {t('remindersView.' + fieldName)}
        </label>
        <div className='col-xs-9 col-sm-10'>
          <ImmutableSelect
            id={fieldName}
            options={this.props[fieldName].list}
            selectedOptions={this.getSelectedOptions(fieldName)}
            label={label}
            name={fieldName}
            onChange={onChange}
            className='form-control'
            placeholder={t('remindersView.' + fieldName)}
            t={t}
          />
        </div>
      </div>
    )
  }

  onTimeFieldChange = timeField => {
    return obj => {
      this.setState({
        reminder: this.state.reminder.set(timeField, obj.time) as Reminder
      })
    }
  }

  getUserLabel = user => {
    const o = user ? user.toJS() : {}
    return o.subjectNumber || o.screeningNumber || o.firstName + ' ' + o.lastName
  }

  canSaveReminder = () => {
    const {reminder} = this.state
    return !reminder.receivers.isEmpty() && reminder.message
  }

  getReminderForm = () => {
    const {reminder} = this.state
    const {t} = this.props

    return (
      <div key='criteria-form' className='form-horizontal criteria-form'>
        <button
          type='button'
          className='btn btn-default hide-criteria-button'
          aria-label='Hide'
          onClick={this.hideCreate}>
          {t('remindersView.hide')}
        </button>
        <InputComponent
          label={t('remindersView.message')}
          id='message'
          name='message'
          type='text'
          value={reminder.message}
          onChange={this.handleMessageChange}
          labelClassName='col-xs-3 col-sm-2'
          wrapperClassName='col-xs-9 col-sm-10'
          t={t}
        />
        {this.createSelectCriteria('groups', 'name', this.onSelectedGroupsChange)}
        {this.createSelectCriteria('users', this.getUserLabel, this.onSelectedUsersChange)}
        <div key='send_time' className='form-group'>
          <label className='col-xs-3 col-sm-2 control-label' htmlFor='criteria'>
            {t('remindersView.sendTime')}
          </label>

          <div className='col-xs-9 col-sm-10'>
            <TimePicker
              id='time'
              time={reminder.sendTime}
              onChange={this.onTimeFieldChange('sendTime')}
              showDateOnlyToggle={false}
              showNow={true}
              error={getFieldError('sendTime', reminder.validate(), reminder.error)}
              t={t}
            />
          </div>
        </div>
        <button
          disabled={!this.canSaveReminder()}
          type='button'
          className='btn btn-default'
          onClick={this.submitReminder}>
          {t('remindersView.submitReminder')}
        </button>
        <button
          disabled={!this.canSaveReminder()}
          type='button'
          className='btn btn-default'
          onClick={this.submitReminderNow}>
          {t('remindersView.submitReminderNow')}
        </button>
      </div>
    )
  }

  getShowCreateButton = () => {
    return (
      <button
        key='show-create-button'
        className='open-btn btn btn-default'
        onClick={this.showCreate}>
        {this.props.t('remindersView.addReminder')}
      </button>
    )
  }

  showCreate = () => this.setState({open: true})

  hideCreate = () => this.setState({open: false})

  resetAndCallCallback = callback => {
    const {reminder} = this.state

    this.setState(
      {
        reminder: this.createNewReminder()
      },
      () => callback(reminder)
    )
  }

  submitReminder = () => this.resetAndCallCallback(this.props.sendCallback)

  submitReminderNow = () => this.resetAndCallCallback(this.props.sendNowCallback)

  getContent = () => {
    if (this.state.open) {
      return this.getReminderForm()
    } else {
      return this.getShowCreateButton()
    }
  }

  render() {
    const classes = classNames({
      open: this.state.open,
      panel: true
    })

    return (
      <div className={classes}>
        <TransitionGroup component='div' className='panel-body'>
          <CSSTransition classNames='reminder' timeout={500}>
            {this.getContent()}
          </CSSTransition>
        </TransitionGroup>
      </div>
    )
  }
}
