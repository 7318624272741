import moment from 'moment'
import {parse} from 'query-string'
import momentLocalizer from 'react-widgets-moment'
import simpleNumberLocalizer from 'react-widgets-simple-number'
import {createAction, handleActions} from 'redux-actions'
import io from 'socket.io-client'
import Config from '../config'
import App from '../models/App'
import ListenerManager, {ListenerContext} from '../services/ListenerManager'
import {get} from './ActionsCommon'
import AuthenticatedUser from './AuthenticatedUser'
import ConversationsModule from './Conversations'
import {navigate, pushState} from './Location'
import {logDebug} from '../util'
import {IDLE_TIME_LIMIT} from '../config/constants'
import {SOCKET_EVENTS} from '../services/socket'
import {initI18n} from '../services/I18n'

// ------------------------------------
// Initial state
// ------------------------------------

const initialState = new App()

let unreadCountInterval
let pingInterval
let checkIdleTimeInterval

let socket

// ------------------------------------
// Constants
// ------------------------------------
export const INITIALIZE_START = 'App.INITIALIZE_START'
export const INITIALIZE_COMPLETE = 'App.INITIALIZE_COMPLETE'
export const INITIALIZE_FAILED = 'App.INITIALIZE_FAILED'
export const CONNECTIVITY = 'App.CONNECTIVITY'
export const NAV_ITEMS_RECEIVE = 'App.NAV_ITEMS_RECEIVE'
export const FEATURES_RECEIVE = 'App.FEATURES_RECEIVE'
export const AVAILABLE_ROLES_RECEIVE = 'App.AVAILABLE_ROLES_RECEIVE'
export const AVAILABLE_FEATURES_RECEIVE = 'App.AVAILABLE_FEATURES_RECEIVE'
export const AVAILABLE_LANGUAGES_RECEIVE = 'App.AVAILABLE_LANGUAGES_RECEIVE'
export const UI_ACTIONS_RECEIVE = 'App.UI_ACTIONS_RECEIVE'
export const RESET = 'App.RESET'
const UNAUTHORIZED = 401
const DISCONNECTED = 0
// ------------------------------------
// Actions
// ------------------------------------
export const startInitialize = createAction(INITIALIZE_START, (payload) => payload)
export const completeInitialize = createAction(INITIALIZE_COMPLETE, (payload) => payload)
export const failedInitialize = createAction(INITIALIZE_FAILED, (payload) => payload)
export const setConnectivity = createAction(CONNECTIVITY)
export const navItemsReceiveAction = createAction(NAV_ITEMS_RECEIVE)
export const featuresReceiveAction = createAction(FEATURES_RECEIVE)
export const availableRolesReceiveAction = createAction(AVAILABLE_ROLES_RECEIVE)
export const availableFeaturesReceiveAction = createAction(AVAILABLE_FEATURES_RECEIVE)
export const availableLanguagesReceiveAction = createAction(AVAILABLE_LANGUAGES_RECEIVE)
export const uiActionsReceiveAction = createAction(UI_ACTIONS_RECEIVE)
export const resetAction = createAction(RESET)

export const initialize = () => {
  return (dispatch, getState) => {
    dispatch(startInitialize())

    initI18n()

    window.addEventListener('storage', this.checkAccessToken(getState))

    momentLocalizer(moment)
    simpleNumberLocalizer()

    window.onpopstate = (_event) => {
      dispatch(pushState(document.location.pathname))
    }

    const {pathname, search} = document.location
    const query = parse(location.search)
    const redirect = query.redirect ? query.redirect : pathname + (search ? search : '')

    if (!/^\/logout.*/.test(pathname)) {
      return dispatch(AuthenticatedUser.getAuthenticatedUser(redirect)).then(() =>
        dispatch(completeInitialize())
      )
    } else {
      return dispatch(completeInitialize())
    }
  }
}

export const teardown = () => {
  return (_dispatch, getState) => {

    window.removeEventListener('storage', this.checkAccessToken(getState))

    if (unreadCountInterval) {
      clearInterval(unreadCountInterval)
    }

    if (pingInterval) {
      clearInterval(pingInterval)
    }

    if (checkIdleTimeInterval) {
      clearInterval(checkIdleTimeInterval)
    }

    if (!!socket && socket.connected) {
      socket.close()
      socket = undefined
    }
  }
}

export function checkAccessToken(getState) {

  return (event: StorageEvent) => {

    const {authenticatedUser} = getState()
    const {authenticated} = authenticatedUser

    if (event.key === 'token') {

      const notLoggedInWithAccessToken = !event.oldValue && !!event.newValue && !authenticated
      const loggedInWithoutAccessToken = !!event.oldValue && !event.newValue && authenticated

      if (notLoggedInWithAccessToken || loggedInWithoutAccessToken) {
        window.location.reload()
      }
    }
  }
}

export function setupIntervals() {
  return (dispatch, getState) => {
    dispatch(teardown())

    const {app} = getState()

    if (app.canViewMessages()) {
      unreadCountInterval = setInterval(function() {
        const {authenticatedUser, app} = getState()

        if (app && authenticatedUser && app.isConnected() && authenticatedUser.authenticated) {
          return dispatch(ConversationsModule.fetchUnreadCount())
        }
      }, 15000)
    }

    pingInterval = setInterval(function() {
      dispatch(pingAuthorization())
    }, 5000)

    checkIdleTimeInterval = setInterval(() => {
      const {authenticatedUser, app} = getState()

      if (app && authenticatedUser && app.isConnected() && authenticatedUser.authenticated) {
        return dispatch(checkIdleTime())
      }
    }, 60000)
  }
}

export function setupSocket() {
  return (dispatch, getState) => {
    const {authenticatedUser} = getState()

    if (!authenticatedUser.authenticated || !authenticatedUser.accessToken) {
      return
    }

    socket = io(
      Config.API_URL,
      {
        query: {
          accessToken: authenticatedUser.accessToken
        },
        autoConnect: false
      }
    )

    socket.on(SOCKET_EVENTS.SEND_MESSAGE, (msg) => {
      const {authenticatedUser, app} = getState()

      if (!authenticatedUser.authenticated) {
        return
      }

      if (app.isConnected()) {
        dispatch(ConversationsModule.fetchUnreadCount())
      }

      ListenerManager.notifyListeners(ListenerContext.messageReceived, msg)
    })

    socket.on(SOCKET_EVENTS.UNLOCKED_CONVERSATION, (conversation) => {
      const {authenticatedUser} = getState()

      if (!authenticatedUser.authenticated) {
        return
      }

      ListenerManager.notifyListeners(ListenerContext.conversationUnlocked, conversation)
    })

    socket.connect()
  }
}

export const actions = {
  initialize
}

export function pingAuthorization() {

  return (dispatch, getState) => {

    const {authenticatedUser} = getState()

    if (authenticatedUser && authenticatedUser.authenticated) {

      return dispatch(get('ping', 'authenticated'))
        .then((_response) => dispatch(setConnectivity(true)))
        .catch((_error) => logDebug(`Failed to ping authenticated.`))
    }
  }
}

export function checkIdleTime() {
  return (dispatch, getState) => {
    const {app} = getState()

    if (app.lastActionTime) {
      const duration = moment().diff(app.lastActionTime, 'minutes')

      if (duration >= IDLE_TIME_LIMIT) {
        dispatch(AuthenticatedUser.logout())
      }
    }
  }
}

export function getNavItems() {
  return (dispatch) => {
    return dispatch(get('user', 'nav-items'))
      .then((navItems) => dispatch(navItemsReceiveAction(navItems)))
      .catch((error) => dispatch(this.handleError(failedInitialize, error)))
  }
}

export function getFeatures() {
  return (dispatch) => {
    return dispatch(get('user', 'features'))
      .then((features) => dispatch(featuresReceiveAction(features)))
      .catch((error) => dispatch(this.handleError(failedInitialize, error)))
  }
}

export function getUIActions() {
  return (dispatch) => {
    return dispatch(get('user', 'ui-actions'))
      .then((roles) => dispatch(uiActionsReceiveAction(roles)))
      .catch((error) => dispatch(this.handleError(failedInitialize, error)))
  }
}

export function getAvailableRoles() {
  return (dispatch) => {
    return dispatch(get('user', 'available-roles'))
      .then((roles) => dispatch(availableRolesReceiveAction(roles)))
      .catch((error) => dispatch(this.handleError(failedInitialize, error)))
  }
}

export function getAvailableFeatures() {
  return (dispatch) => {
    return dispatch(get('user', 'available-features'))
      .then((roles) => dispatch(availableFeaturesReceiveAction(roles)))
      .catch((error) => dispatch(this.handleError(failedInitialize, error)))
  }
}

export function getAvailableLanguages() {
  return (dispatch) => {
    return dispatch(get('user', 'available-languages'))
      .then((roles) => dispatch(availableLanguagesReceiveAction(roles)))
      .catch((error) => dispatch(this.handleError(failedInitialize, error)))
  }
}

export function handleGenericError(error) {
  return (dispatch) => {

    switch (error.code) {
      case UNAUTHORIZED:
        dispatch(AuthenticatedUser.resetAuthenticatedUser())
        dispatch(resetAction())
        dispatch(navigate('/'))
        break
      case DISCONNECTED:
        dispatch(setConnectivity(false))
        break
      default:
    }

    throw error
  }
}

// ------------------------------------
// Action Handlers
// ------------------------------------

const startOfInitialize = (state) => {
  return state
    .set('initializing', true)
    .set('initialized', false)
    .set('error', null)
}

const endOfInitialize = (state, error?) => {
  return state
    .set('initializing', false)
    .set('initialized', !error)
    .set('error', error)
}

const ACTION_HANDLERS = {
  [INITIALIZE_START]: (state) => startOfInitialize(state),
  [INITIALIZE_COMPLETE]: (state) => endOfInitialize(state),
  [INITIALIZE_FAILED]: (state, {payload}) => endOfInitialize(state, payload),
  [NAV_ITEMS_RECEIVE]: (state, {payload}) => state.setNavItems(payload),
  [FEATURES_RECEIVE]: (state, {payload}) => state.setFeatures(payload),
  [AVAILABLE_ROLES_RECEIVE]: (state, {payload}) => state.setAvailableRoles(payload),
  [AVAILABLE_FEATURES_RECEIVE]: (state, {payload}) => state.setAvailableFeatures(payload),
  [AVAILABLE_LANGUAGES_RECEIVE]: (state, {payload}) => state.setAvailableLanguages(payload),
  [UI_ACTIONS_RECEIVE]: (state, {payload}) => state.setUIActions(payload),
  [CONNECTIVITY]: (state, {payload}) => state.setConnectivity(payload),
  [RESET]: () => endOfInitialize(initialState)
}

// ------------------------------------
// Reducer
// ------------------------------------
export default handleActions(ACTION_HANDLERS, initialState)
