/**
 * Created by Mauritz Untamala on 24/09/15.
 */
import React from 'react'
import {PureComponent} from 'react'
import {ControlLabel, FormControl, FormGroup, HelpBlock} from 'react-bootstrap'
import NumericInput from 'react-numeric-input'

import _ from 'lodash'
import classNames from 'classnames'

interface Props {
  value: string
  onChange: (value) => any
  id?: string
  name?: string
  placeholder?: string
  disabled?: boolean
  type?: string
  wrapperClassName?: string
  label?: (() => string | React.ReactNode) | (string | React.ReactNode)
  labelClassName?: string
  i18nLoadedAt?: any
  error?: any
  forceShowError?: boolean
  groupClassName?: string
  className?: string
  afterInput?: React.ReactNode
  t?: (key, params?) => any
  min?: number
  autoFocus?: boolean
  onBlur?: () => any
  tabIndex?: number
}

interface State {
  showError: boolean
  forceShowError: boolean
}

export default class InputComponent extends PureComponent<Props, State> {
  textInput: any

  constructor(props: Props) {
    super(props)
    this.state = {
      showError: this.props.forceShowError,
      forceShowError: this.props.forceShowError
    }
  }

  componentDidUpdate(prevProps) {
    const {forceShowError} = this.props

    if (prevProps.forceShowError !== forceShowError) {
      this.setState({forceShowError, showError: forceShowError})
    }
  }

  onBlur = () => this.setState({showError: true})

  onFocus = () => this.setState({showError: this.props.forceShowError ? true : false})

  focus = () => this.textInput.focus()

  shouldShowError = () =>
    this.props.error && this.state.showError

  getError = () => {
    if (this.shouldShowError()) {
      return <div className='error'>{this.props.t(this.props.error)}</div>
    }
  }

  onInputChange = event => {
    let inputValue = event.target.value
    if (inputValue === '') {
      inputValue = undefined
    }

    return this.props.onChange(inputValue)
  }

  renderLabel = (label, labelClassName) => {
    if (label) {
      return (
        <ControlLabel bsClass={classNames('control-label', labelClassName)}>
          {_.isFunction(label) ? label() : label}
        </ControlLabel>
      )
    }
  }

  refFunc = (input) => {
    this.textInput = input
  }

  renderInput = () => {

    const {
      label,
      labelClassName,
      error,
      onChange,
      value,
      disabled,
      wrapperClassName,
      type,
      groupClassName,
      afterInput,
      forceShowError,
      ...rest
    } = this.props
    const restProps = _.omit(rest, ['t'])

    if (type === 'number') {

      return (
        <NumericInput
          className={classNames('form-control', restProps.className)}
          ref={this.refFunc}
          onBlur={this.onBlur}
          onFocus={this.onFocus}
          onChange={onChange}
          disabled={disabled}
          value={value}
          {..._.omit(restProps, 'className')}
        />
      )

    } else {

      return (
        <FormControl
          ref={this.refFunc}
          componentClass={type === 'textarea' ? 'textarea' : undefined}
          onBlur={this.onBlur}
          onFocus={this.onFocus}
          onChange={this.onInputChange}
          disabled={disabled}
          value={value || ''}
          type={type === 'textarea' ? undefined : type}
          {...restProps}
        />
      )
    }
  }

  render() {
    let formGroupClasses = classNames({
      'form-group': true,
      'has-error': this.shouldShowError()
    })

    const {
      label,
      labelClassName,
      wrapperClassName,
      groupClassName,
      afterInput
    } = this.props
    const groupClassNameProp = classNames(formGroupClasses, groupClassName)
    const help = this.getError()

    return (
      <FormGroup bsClass={groupClassNameProp} type={'text'}>
        {this.renderLabel(label, labelClassName)}
        <div className={wrapperClassName}>
          {this.renderInput()}
          {help && <HelpBlock>{help}</HelpBlock>}
        </div>
        {afterInput}
      </FormGroup>
    )
  }
}
