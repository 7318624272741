import React from 'react'
import _ from 'lodash'
import {PureComponent} from 'react'
import Results from './Results'
import {getFilterValue, getSortValue} from './TabComponents'
import {List} from 'immutable'

import App from '../../../../models/App'
import QuestionnaireStatusModel from '../../../../models/QuestionnaireStatus'
import Criteria from '../../../../models/Criteria'
import BooleanColumn from './BooleanColumn'
import TimestampUtcColumn from '../../../../components/TimestampUtcColumn'

const questionnaireStatusColumns = [
  'siteStudyName',
  'person',
  'createTime',
  'updateTime',
  'period',
  'title',
  'isAnswered'
]

const notSortableColumns = [
  'period',
  'title'
]

interface Props {
  app: App
  questionnaireStatus: List<QuestionnaireStatusModel>
  criteria: Criteria
  onCriteriaChanged: (criteria) => any
  navigate: (url: string) => any

  showFilter?: boolean
  hasMore?: boolean
  loading?: boolean
  loadMore?: () => any
  onProcessedData?: () => any

  t: (key, params?) => any
}

interface State {
}

export default class QuestionnaireStatus extends PureComponent<Props, State> {

  getCustomComponent = field => {
    switch (field) {
      case 'createTime':
      case 'updateTime':
        return TimestampUtcColumn
      case 'isAnswered':
        return BooleanColumn
      default:
        return null
    }
  }

  getColumns = () => {
    const {t} = this.props
    return questionnaireStatusColumns.map(field => {
      return {
        columnName: field,
        displayName: t('column.' + field),
        visible: true,
        customComponent: this.getCustomComponent(field),
        filterValue: getFilterValue(this.props, field),
        sortValue: getSortValue(this.props, field),
        sortable: !_.includes(notSortableColumns, field)
      }
    })
  }

  onRowClick = ({questionnaireAnswerId, id, isAnswered}) => {

    if (isAnswered) {

      const {app, navigate} = this.props
      const path = `/admin/questionnaires/${id}/answer/${questionnaireAnswerId}`

      if (app.hasAccessToPage(path)) {
        navigate(path)
      }
    }
  }

  render() {

    const {questionnaireStatus} = this.props

    return (
      <Results
        columns={this.getColumns()}
        results={questionnaireStatus}
        ref='results'
        rowKey={'rowId'}
        onRowClick={this.onRowClick}
        {...this.props}
      />
    )
  }
}
