import ModelModule from './ModelModule'
import QuestionnaireAnswer from '../models/QuestionnaireAnswer'
import {bind} from '../util'

export class QuestionnaireAnswerModule extends ModelModule<QuestionnaireAnswer> {
  constructor() {
    super('questionnaire/answer', 'questionnaireAnswer')
    bind(this, this.resetQuestionnaireAnswer)
  }

  protected getInitialState = () => new QuestionnaireAnswer()

  resetQuestionnaireAnswer(questionnaire) {
    return (dispatch, getState) => {
      const personId = getState().authenticatedUser.id

      const payload = {
        questionnaireId: questionnaire ? questionnaire.id : undefined,
        questionnaire,
        personId
      }

      dispatch(this.resetModelAction(payload))
    }
  }

  /**
   * @override
   */
  protected getAdditionalActionHandlers() {
    return {
      [this.resetModelType]: (_state, {payload}) => new QuestionnaireAnswer(payload)
    }
  }
}

export default new QuestionnaireAnswerModule()
