import Questionnaires from '../models/Questionnaires'
import ModelsModule from './ModelsModule'
import Questionnaire from './Questionnaire'
import {createAction} from 'redux-actions'
import {get, update, add} from './ActionsCommon'
import {success} from './Notifications'
import i18n from '../services/I18n'
import {bind} from '../util'
import {List} from 'immutable'
import {downloadSuccess} from './Download'

const initialState = new Questionnaires()

export class QuestionnairesModule extends ModelsModule {

  protected exportQuestionnairesRequestType: any
  protected exportQuestionnairesSuccessType: any
  protected exportQuestionnairesFailedType: any
  protected exportQuestionnairesRequestAction: any
  protected exportQuestionnairesSuccessAction: any
  protected exportQuestionnairesFailedAction: any

  private changeOrderRequestType
  private changeOrderSuccessType
  private changeOrderFailedType
  private changeOrderRequestAction
  private changeOrderSuccessAction
  private changeOrderFailedAction
  private importQuestionnairesRequestType
  private importQuestionnairesSuccessType
  private importQuestionnairesFailedType
  private importQuestionnairesRequestAction
  private importQuestionnairesSuccessAction
  private importQuestionnairesFailedAction

  constructor() {

    super('questionnaire', initialState)
    bind(this, this.changeOrder, this.import, this.exportQuestionnaires)
  }

  public changeOrder(questionnaireId, srcOrder, dstOrder) {

    return (dispatch, getState) => {

      const orderedQuestionnaires = getState().questionnaires.reOrderQuestionnaires(srcOrder, dstOrder)

      dispatch(this.changeOrderRequestAction(orderedQuestionnaires))

      const payload = {questionnaireId, srcOrder, dstOrder}

      return dispatch(update(this.name, 'order', undefined, payload))
        .then(response => dispatch(this.changeOrderSuccessAction(response)))
        .catch((err) => dispatch(this.changeOrderFailedAction(err)))
    }
  }

  public import(file) {
    return dispatch => {
      const formData = new FormData()

      formData.append('files', file)
      formData.append('type', file.type)

      dispatch(this.importQuestionnairesRequestAction(file))

      return dispatch(add(this.name, 'import', undefined, formData, true))
        .then(result => {
          dispatch(this.importQuestionnairesSuccessAction(result))
          success(i18n.t('success'))
        })
        .catch(errorPayload => dispatch(this.importQuestionnairesFailedAction(errorPayload)))
    }
  }
  public exportQuestionnaires(ids: List<number>) {

    return (dispatch) => {
      dispatch(this.exportQuestionnairesRequestAction())

      const queryParams = {
        ids: ids.toArray()
      }

      return dispatch(get(this.name, 'export', queryParams))
        .then((response) => {

          dispatch(this.exportQuestionnairesSuccessAction(response))

          return dispatch(downloadSuccess(response))
        })
        .catch((err) => dispatch(this.exportQuestionnairesFailedAction(err)))
    }
  }

  protected getAdditionalActionHandlers() {

    return {
      [Questionnaire.getCreateModelSuccessType()]: (state, {payload}) =>
        state.addModelFromJS(payload),
      [Questionnaire.getUpdateModelSuccessType()]: (state, {payload}) =>
        state.addModelFromJS(payload),
      [Questionnaire.getDeleteModelSuccessType()]: (state, {payload}) =>
        state.removeModelWithId(payload.id),
      [this.changeOrderRequestType]: (_state, {payload}) => payload.startOfSaving(),
      [this.changeOrderSuccessType]: (state, {payload}) => state.endOfSaving().setModelsFromJS(payload),
      [this.changeOrderFailedType]: (state, {payload}) => state.endOfSaving(payload),
      [this.importQuestionnairesRequestType]: (state) => state.startOfSaving(),
      [this.importQuestionnairesSuccessType]: (state, {payload}) => state.endOfSaving().addModelsFromJS(payload),
      [this.importQuestionnairesFailedType]: (state, {payload}) => state.endOfSaving(payload),
      [this.exportQuestionnairesRequestType]: (state) => state.startOfLoading(),
      [this.exportQuestionnairesSuccessType]: (state, _payload) => state.endOfLoading(),
      [this.exportQuestionnairesFailedType]: (state, {payload}) => state.endOfLoading(payload)
    }
  }

  protected initializeTypes() {

    super.initializeTypes()

    this.changeOrderRequestType = `${this.name}.CHANGE_ORDER_REQUEST`
    this.changeOrderSuccessType = `${this.name}.CHANGE_ORDER_SUCCESS`
    this.changeOrderFailedType = `${this.name}.CHANGE_ORDER_FAILED`
    this.importQuestionnairesRequestType = `${this.name}.IMPORT_QUESTIONNAIRES_REQUEST`
    this.importQuestionnairesSuccessType = `${this.name}.IMPORT_QUESTIONNAIRES_SUCCESS`
    this.importQuestionnairesFailedType = `${this.name}.IMPORT_QUESTIONNAIRES_FAILED`

    this.exportQuestionnairesRequestType = `${this.name}.EXPORT_QUESTIONNAIRES_REQUEST`
    this.exportQuestionnairesSuccessType = `${this.name}.EXPORT_QUESTIONNAIRES_SUCCESS`
    this.exportQuestionnairesFailedType = `${this.name}.EXPORT_QUESTIONNAIRES_FAILED`
  }

  protected initializeActions() {

    super.initializeActions()

    this.changeOrderRequestAction = createAction(this.changeOrderRequestType)
    this.changeOrderSuccessAction = createAction(this.changeOrderSuccessType)
    this.changeOrderFailedAction = createAction(this.changeOrderFailedType)
    this.importQuestionnairesRequestAction = createAction(this.importQuestionnairesRequestType)
    this.importQuestionnairesSuccessAction = createAction(this.importQuestionnairesSuccessType)
    this.importQuestionnairesFailedAction = createAction(this.importQuestionnairesFailedType)

    this.exportQuestionnairesRequestAction = createAction(this.exportQuestionnairesRequestType)
    this.exportQuestionnairesSuccessAction = createAction(this.exportQuestionnairesSuccessType)
    this.exportQuestionnairesFailedAction = createAction(this.exportQuestionnairesFailedType)
  }
}

export default new QuestionnairesModule()
