import React from 'react'
import {Component} from 'react'
import ReactPhoneInput from 'react-phone-input-2'
import {HelpBlock} from 'react-bootstrap'

import _ from 'lodash'
import classNames from 'classnames'

import 'react-phone-input-2/lib/style.css'

const DefaultCountry = ''
const PreferredCountries = ['fi', 'de', 'ie', 'nl', 'pl']

interface Props {
  field: string
  modelId: number
  disabled: boolean
  value: string
  error: string
  onChange: (value, country) => any
  t: (key, params?) => any
}

interface State {
  showError: boolean
}

export default class PhoneNumberInput extends Component<Props, State> {
  constructor(props: Props) {
    super(props)
    this.state = {showError: false}
  }

  onBlur = () => this.setState({showError: true})

  onFocus = () => this.setState({showError: false})

  onChange = (value, data) => {

    const {onChange} = this.props

    const phone = value ? `+${value}` : null
    const dialCode = value && data ? data.dialCode : null

    onChange(phone, dialCode)
  }

  // Workaround for issue 'onChange event does not fire on last character deletion'
  // Hope they will fix the bug and then below can be removed.
  // https://github.com/bl00mber/react-phone-input-2/issues/231
  onKeyDown = event => {

    const {value} = this.props

    const reportNull = (event.key === 'Backspace')
      && value && this.stripPrefix(value).length === 1

    if (reportNull) {

      this.onChange(null, null)
    }
  }

  shouldShowError = () => {
    const {error, value} = this.props
    const {showError} = this.state

    return error && (showError || !_.isEmpty(value))
  }

  stripPrefix = value => value ? value.slice(1) : ''

  render() {
    const {field, modelId, disabled, value, error, t} = this.props
    const showError = this.shouldShowError()
    const wrapperClassName = classNames({
      'form-group': true,
      'has-error': showError
    })

    const inputStyle = {
      width: '100%'
    }

    const helpBlock = (showError: boolean) => {
      if (showError) {
        return (
          <HelpBlock>
            <div className='error'>{t(error)}</div>
          </HelpBlock>
        )
      }
    }

    return (
      <div key={`phone-input-user-${modelId}`} className={wrapperClassName}>
        <label className='control-label col-xs-3 col-sm-2'>
          {t(field)}
        </label>
        <div className={classNames(['col-xs-9 col-sm-10', field])}>
          <ReactPhoneInput
            country={DefaultCountry}
            placeholder={''}
            preferredCountries={PreferredCountries}
            disabled={disabled}
            value={this.stripPrefix(value)}
            inputStyle={inputStyle}
            onBlur={this.onBlur}
            onFocus={this.onFocus}
            onChange={this.onChange}
            onKeyDown={this.onKeyDown}
          />
          {helpBlock(showError)}
        </div>
      </div>
    )
  }
}
