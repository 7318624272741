import {Record} from 'immutable'

const state = Record({
  accessRights: undefined,
  activityTracker: undefined,
  activityTrackers: undefined,
  administrationEvent: undefined,
  administrationEvents: undefined,
  adverseEvent: undefined,
  adverseEvents: undefined,
  adverseEventOptions: undefined,
  app: undefined,
  auditTrail: undefined,
  auditTrails: undefined,
  authenticatedUser: undefined,
  conversations: undefined,
  download: undefined,
  location: undefined,
  medicationEvent: undefined,
  medicationEvents: undefined,
  message: undefined,
  messages: undefined,
  pagination: undefined,
  period: undefined,
  reminder: undefined,
  reminders: undefined,
  reports: undefined,
  routing: undefined,
  site: undefined,
  sites: undefined,
  siteStudy: undefined,
  siteStudies: undefined,
  smss: undefined,
  study: undefined,
  studies: undefined,
  studyGroup: undefined,
  studyGroups: undefined,
  user: undefined,
  users: undefined,
  questionnaire: undefined,
  questionnaires: undefined,
  questionnaireAnswer: undefined,
  questionnaireAnswers: undefined,
  localization: undefined,
  localizations: undefined
})

export default state
