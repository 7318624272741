import React from 'react'
import {PureComponent} from 'react'
import TimestampColumn from '../../../../components/TimestampColumn'
import TableComponent from '../../../../components/TableComponent'

import _ from 'lodash'
import {List} from 'immutable'

import AuditTrail from '../../../../models/AuditTrail'
import Criteria from '../../../../models/Criteria'
import User from '../../../../models/User'

interface Props {
  audits: List<AuditTrail>
  criteria: Criteria
  hasMore: boolean
  loading: boolean
  loadMore: () => any
  onCriteriaChange: (criteria) => any
  navigate: (url: string) => any
  ref: any
  t: (key, params?) => any
}

interface State {
}

export default class Results extends PureComponent<Props, State> {
  getResults = () =>
    this.props.audits.map(audit => _.extend(audit.toJS(), {_src: audit})).toArray()

  filterBy = (filter, audit) => {
    let {criteria, onCriteriaChange} = this.props

    function updateCriteriaList(listName, id) {
      onCriteriaChange(
        criteria.set(
          listName,
          criteria
            .get(listName)
            .clear()
            .push(id)
        )
      )
    }

    switch (filter) {
      case 'user':
        if (audit.who && audit.who.id) {
          updateCriteriaList('users', audit.who.id)
        }
        break
      default:
        console.log('Unknown filter:', filter)
        break
    }
  }

  renderUserColumn = ({data}) => {
    let name = User.visibleNameFromJS(data)

    if (!name) {
      name = data && data.name ? data.name : this.props.t('unknown')
    }

    return <div>{name}</div>
  }

  renderNotAllowed = () => {
    const {t} = this.props

    return t('notAllowed')
  }

  createFilterComponent = () => {
    return props => {
      if (!props.data) {
        return this.renderNotAllowed()
      }

      if (!props.data.id) {
        return this.renderUserColumn(props)
      }

      return (
        <a onClick={() => this.filterBy('user', props.rowData)} role='button'>
          {this.renderUserColumn(props)}
        </a>
      )
    }
  }

  renderJSONColumn = ({rowData, data}) => {
    const showAudit = () => this.props.navigate('/admin/audit-trail/' + rowData.id)

    const getContent = () => {
      if (data) {
        return JSON.stringify(data, undefined, 2)
      } else {
        return this.props.t('noContent')
      }
    }

    return (
      <a onClick={showAudit} role='button'>
        <div className='json-content'>{getContent()}</div>
      </a>
    )
  }

  visibleName = item => {
    return item ? User.visibleNameFromJS(item) : ''
  }

  getColumnSettings = () => {
    const {t} = this.props

    return [
      {
        columnName: 'who',
        displayName: t('column.user'),
        customComponent: this.createFilterComponent(),
        filterValue: this.visibleName,
        sortValue: this.visibleName,
        sortable: false
      },
      {
        columnName: 'what',
        displayName: t('column.what'),
        sortable: true
      },
      {
        columnName: 'time',
        displayName: t('column.time'),
        customComponent: TimestampColumn,
        sortable: true
      },
      {
        columnName: 'original',
        displayName: t('column.original'),
        customComponent: this.renderJSONColumn,
        sortable: false
      },
      {
        columnName: 'updated',
        displayName: t('column.updated'),
        customComponent: this.renderJSONColumn,
        sortable: false
      }
    ]
  }

  onTabCriteriaChanged = tabCriteria => {
    this.props.onCriteriaChange(
      this.props.criteria
        .set('columnName', tabCriteria ? tabCriteria.columnName : undefined)
        .set('sort', tabCriteria ? tabCriteria.sort : undefined)
        .set('filter', tabCriteria ? tabCriteria.filter : undefined)
    )
  }

  render() {
    let {criteria, hasMore, loadMore, loading, t} = this.props

    return (
      <div className='table-panel'>
        <TableComponent
          key='results'
          ref='results'
          data={this.getResults()}
          showFilter={true}
          rowKey={'id'}
          columns={this.getColumnSettings()}
          useFixedHeader={true}
          filter={criteria ? criteria.filter : undefined}
          initialSortColumn={criteria ? criteria.columnName : undefined}
          initialSortState={criteria ? criteria.sort : undefined}
          hasMore={hasMore}
          loadMore={loadMore}
          loading={loading}
          onCriteriaChanged={this.onTabCriteriaChanged}
          t={t}
        />
      </div>
    )
  }
}
