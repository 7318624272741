import React from 'react'
import {PureComponent} from 'react'
import Questionnaire from '../../../models/Questionnaire'
import Study from '../../../models/Study'
import Select from 'react-select'
import _ from 'lodash'
import TranslationInput from './TranslationInput'
import Toggle from 'react-toggle'
import {
  LANGUAGE_STATUS,
  LANG_COLOR_VALID_PUBLISHED,
  LANG_COLOR_VALID_NOT_PUBLISHED,
  LANG_COLOR_NOT_VALID,
  SelectOption
} from '../../../config/constants'

interface Props {
  updateModel: (questionnaire) => any
  t: (key, params?) => any
  model: Questionnaire
  study: Study
}

interface State {
  studyLanguageOptions: any[]
  selectedOptions: any[]
  translationLanguageOptions: SelectOption<string>[]
  selectedTranslation: any
}

const colourStyles = {
  multiValue: (styles, {data, isDisabled}) => {

    const backgroundColorByStatus = data.isValid
      ? data.isPublished ? LANG_COLOR_VALID_PUBLISHED : LANG_COLOR_VALID_NOT_PUBLISHED
      : LANG_COLOR_NOT_VALID

    const shouldUseDefaultBackgroundColor = isDisabled
      || (data.isDefault && data.isValid) || _.isNil(data.isValid)

    return {
      ...styles,
      backgroundColor: shouldUseDefaultBackgroundColor
        ? styles.backgroundColor
        : backgroundColorByStatus
    }
  },
  multiValueRemove: (base, state) => {
    return state.data.isDefault ? {...base, display: 'none'} : base
  }
}

export default class QuestionnaireTranslations extends PureComponent<Props, State> {

  constructor(props) {
    super(props)

    const studyLanguageOptions = this.getStudyLanguageOptions()
    const translationLanguageOptions = this.getTranslationLanguageOptions()
    const firstLanguage = props.model.getSortedAdditionalLanguages().first()
    const selectedTranslation = firstLanguage
      ? translationLanguageOptions.find(o => o.value === firstLanguage.language)
      : undefined
    const selectedOptions = this.getSelectedOptions(studyLanguageOptions)

    this.state = {
      studyLanguageOptions,
      selectedOptions,
      translationLanguageOptions,
      selectedTranslation
    }
  }

  componentDidUpdate(prevProps: Props, prevState: State) {
    const {study, model} = this.props
    let {studyLanguageOptions, translationLanguageOptions, selectedTranslation} = this.state

    if (study.languages !== prevProps.study.languages
      || model.getDefaultLanguageCode() !== prevProps.model.getDefaultLanguageCode()) {

      studyLanguageOptions = this.getStudyLanguageOptions()

      this.setState({studyLanguageOptions})
    }

    if (model !== prevProps.model || studyLanguageOptions !== prevState.studyLanguageOptions) {

      const selectedOptions = this.getSelectedOptions(studyLanguageOptions)

      this.setState({selectedOptions})
    }

    const additionalLanguages = model.getAdditionalLanguages()

    if (!additionalLanguages.equals(prevProps.model.getAdditionalLanguages())) {

      translationLanguageOptions = this.getTranslationLanguageOptions()
      this.setState({translationLanguageOptions})

      if (selectedTranslation && !additionalLanguages.some(l => l.language === selectedTranslation.value)
        || !selectedTranslation) {

        const firstLanguage = additionalLanguages.first()
        selectedTranslation = firstLanguage
          ? translationLanguageOptions.find(o => o.value === firstLanguage.language)
          : undefined

        this.setState({selectedTranslation})
      }
    }
  }

  getStudyLanguageOptions = () => {

    const {t, study, model} = this.props
    const defaultLanguageCode = model.getDefaultLanguageCode()

    return study.languages
      .sort()
      .map(language => {

        const isDefault = language === defaultLanguageCode

        return {
          value: language,
          label: t(`language.${language}`),
          isDefault
        }
      })
      .toArray()
  }

  getTranslationLanguageOptions = () => {

    const {t, model} = this.props

    return model.getSortedAdditionalLanguages()
      .map((questionnaireLanguage) => {

        return {
          value: questionnaireLanguage.language,
          label: t(`language.${questionnaireLanguage.language}`)
        }
      })
      .toArray()
  }

  getSelectedOptions = (options) => {

    const {model} = this.props
    const selectedOptions = options
      .filter(option => !!model.getLanguage(option.value))
      .map(option => {

        const isValid = !model.validateByLanguage(option.value)
        const isPublished = model.getLanguage(option.value).isPublished()

        return {
          ...option,
          isValid,
          isPublished
        }
      })

    return selectedOptions
      .filter(o => o.isDefault)
      .concat(selectedOptions.filter(o => !o.isDefault))
  }

  onQuestionnaireLanguagesChange = (value, {action, removedValue}) => {

    const {selectedTranslation} = this.state

    switch (action) {
      case 'remove-value':
      case 'pop-value':
        if (removedValue.isDefault) {
          return
        }
        break
      case 'clear':
        value = this.state.studyLanguageOptions.filter((v) => v.isDefault)
        break
      default:
        break
    }

    const {model, updateModel} = this.props
    const languages = (value || []).map(option => option.value)

    if (selectedTranslation && !languages.some(lang => selectedTranslation.value === lang)) {
      this.setState({selectedTranslation: undefined})
    }

    updateModel(model.setAdditionalLanguages(languages))
  }

  renderQuestionnaireLanguages = () => {

    const {studyLanguageOptions, selectedOptions} = this.state

    return (
      <Select key='questionnaire-languages'
              name='languages'
              isMulti={true}
              isClearable={selectedOptions.some(v => !v.isDefault)}
              options={studyLanguageOptions}
              value={selectedOptions}
              onChange={this.onQuestionnaireLanguagesChange}
              styles={colourStyles}
              noOptionsMessage={() => this.props.t('noOptions')}/>
    )
  }

  onTranslationLanguageChange = (selectedTranslation) => this.setState({selectedTranslation})

  renderSelectTranslationLanguage = () => {

    const {t} = this.props
    const {translationLanguageOptions, selectedTranslation} = this.state

    if (translationLanguageOptions.length === 1 && selectedTranslation) {

      return <div>{t(`language.${selectedTranslation.value}`)}</div>
    }

    return (
      <Select
        key='translation-language'
        name='translation-language'
        className='translation-language-select'
        isMulti={false}
        isClearable={false}
        options={translationLanguageOptions}
        value={selectedTranslation}
        onChange={this.onTranslationLanguageChange}
      />
    )
  }

  renderTranslationLanguageStatistics = () => {

    const {t, model} = this.props
    const {selectedTranslation} = this.state
    const count = model.getLanguageTranslations(selectedTranslation.value).filter(t => !!t.value).length
    const expected = model.getLanguageTranslations(model.getDefaultLanguageCode()).filter(t => !!t.value).length

    return (
      <div>{t(`questionnaire.translationStatistics`, {count, expected})}</div>
    )
  }

  renderTogglePublishLanguage = () => {

    const {t, model, updateModel} = this.props
    const {selectedTranslation} = this.state
    let language = model.getLanguage(selectedTranslation.value)

    const onChange = (event) => {
      updateModel(model.updateLanguage(
        language.setStatus(event.target.checked ? LANGUAGE_STATUS.PUBLISHED : LANGUAGE_STATUS.DRAFT
        )
      ))
    }

    return (
      <div className='toggle-publish-language'>
        <label className='control-label' htmlFor='toggle_publish'>
          {t('questionnaire.publishedLanguage')}
        </label>
        <Toggle
          id='toggle_publish'
          key={'toggle_publish'}
          checked={language.isPublished()}
          onChange={onChange}
        />
      </div>
    )
  }

  renderAdditionalLanguageColumnHeader = () => {

    const {selectedTranslation} = this.state

    if (!selectedTranslation) {
      return
    }

    return (
      <div className='translations-column additional-language'>
        {this.renderSelectTranslationLanguage()}
        {this.renderTogglePublishLanguage()}
        {this.renderTranslationLanguageStatistics()}
      </div>
    )
  }

  renderTranslationInput = (language, field, page, order, localizationKey, value, tabIndex, key, showError = false) => {

    const onChange = (updatedValue) => {

      let {model, updateModel} = this.props
      let updatedModel

      if (_.isNil(page)) {
        updatedModel = model.setField(field, updatedValue, language)
      } else {

        let question = model.getQuestion(page, order)

        if (field === 'title') {
          question = question.setField(field, updatedValue, language)
        } else {
          const component = question.getComponent(field)
          const localization = component.getLocalization()

          if (localizationKey) {
            localization[language]
              ? localization[language][localizationKey] = updatedValue
              : localization[language] = {[localizationKey]: updatedValue}
          } else {
            localization[language] = updatedValue
          }

          question = question.updateComponent(component.setLocalization(localization))
        }

        updatedModel = model.updateQuestion(question)
      }

      updateModel(updatedModel)
    }

    return (
      <TranslationInput key={key} tabIndex={tabIndex} value={value} showError={showError} onChange={onChange}/>
    )
  }

  renderTranslationRows = () => {

    const {model} = this.props
    const {selectedTranslation} = this.state
    const defaultLanguageCode = model.getDefaultLanguageCode()
    const defaultLanguageTranslations = model.getLanguageTranslations(defaultLanguageCode).filter(l => l.value)

    if (!selectedTranslation) {

      return (
        defaultLanguageTranslations.map((translationDetail, index) => {

          const {field, page, order, localizationKey, value} = translationDetail
          const translationInput = this.renderTranslationInput(
            defaultLanguageCode,
            field,
            page,
            order,
            localizationKey,
            value,
            0,
            'default' + index
          )

          return (
            <div key={`translations-row-${index}`} className='translations-row'>
              {translationInput}
            </div>
          )
        })
      )
    }

    const selectedLanguage = selectedTranslation.value
    const selectedLanguageTranslations = model.getLanguageTranslations(selectedLanguage)
    const rowCount = defaultLanguageTranslations.length
    const showErrors = model.getLanguage(selectedLanguage).isPublished()

    return (
      defaultLanguageTranslations.map((translationDetail, index) => {

        const {field, page, order, localizationKey, value} = translationDetail
        const selectedLanguageTranslationDetail = selectedLanguageTranslations
          .find(l => l.field === field
            && l.page === page
            && l.order === order
            && l.localizationKey === localizationKey)
        const valueInput = this.renderTranslationInput(
          defaultLanguageCode,
          field,
          page,
          order,
          localizationKey,
          value,
          index + 1,
          'default' + index
        )
        const translationInput = this.renderTranslationInput(
          selectedLanguage,
          field,
          page,
          order,
          localizationKey,
          selectedLanguageTranslationDetail && selectedLanguageTranslationDetail.value,
          rowCount + index + 1,
          'selected' + index,
          showErrors
        )

        return (
          <div key={`translations-row-${index}`} className='translations-row'>
            {valueInput}
            {translationInput}
          </div>
        )
      })
    )
  }

  renderTranslations = () => {

    const {t, model} = this.props
    const defaultLanguageCode = model.getDefaultLanguageCode()

    if (!defaultLanguageCode) {
      return
    }

    return (
      <div className='translations'>
        <div className='translations-row translations-header'>
          <div className='translations-column'>{t(`language.${defaultLanguageCode}`)}</div>
          {this.renderAdditionalLanguageColumnHeader()}
        </div>
        {this.renderTranslationRows()}
      </div>
    )
  }

  render() {

    return (
      <div className='questionnaire-translations'>
        {this.renderQuestionnaireLanguages()}
        {this.renderTranslations()}
      </div>
    )
  }
}
