import NavigationLayout from '../../layouts/NavigationLayout'
import AppLayout from '../../layouts/AppLayout/AppLayout'
import Profile from './Profile'
import StudyInfo from './StudyInfo'
import Subjects from './Subjects'
import StudyGroups from './StudyGroups'
import Reports from './Reports'
import Reminders from './Reminders'
import Studies from './Studies'
import Study from './Study'
import Sites from './Sites'
import Site from './Site'
import SiteStudy from './SiteStudy'
import Personnel from './Personnel'
import AuditTrails from './AuditTrails'
import User from './User'
import Trackers from './Trackers'
import Tracker from './Tracker'
import Messages from './Messages'
import AuditTrail from './AuditTrail'
import StudyGroup from './StudyGroup'
import AdverseEvents from './AdverseEvents'
import AdverseEvent from './AdverseEvent'
import MedicationEvent from './MedicationEvent'
import QuestionnaireAnswer from '../Subject/Questionnaires/QuestionnaireAnswer'
import Questionnaires from './Questionnaires/Questionnaires'
import Questionnaire from './Questionnaires/Questionnaire'
import PreviewQuestionnaires from './Questionnaires/PreviewQuestionnaires'

export default {
  component: AppLayout,
  path: 'admin',
  childRoutes: [
    {
      component: NavigationLayout,
      childRoutes: [
        {path: 'profile', component: Profile},
        {path: 'study-info', component: StudyInfo},
        {path: 'subjects', component: Subjects},
        {path: 'study-groups', component: StudyGroups},
        {path: 'reports', component: Reports},
        {path: 'reminders', component: Reminders},
        {path: 'studies', component: Studies},
        {path: 'sites', component: Sites},
        {path: 'personnel', component: Personnel},
        {path: 'audit-trails', component: AuditTrails},
        {path: 'trackers', component: Trackers},
        {path: 'messages', component: Messages},
        {path: 'adverse-events', component: AdverseEvents},
        {path: 'questionnaires', component: Questionnaires}
      ]
    },
    {path: 'study/new', component: Study},
    {path: 'study/:id', component: Study},
    {path: 'site/new', component: Site},
    {path: 'site/:id', component: Site},
    {path: 'study-group/new', component: StudyGroup},
    {path: 'study-group/:id', component: StudyGroup},
    {path: 'site/:siteId/study/new', component: SiteStudy},
    {path: 'site/:siteId/study/:id', component: SiteStudy},
    {path: 'user/:id', component: User},
    {path: 'tracker/new', component: Tracker},
    {path: 'tracker/:id', component: Tracker},
    {path: 'audit-trail/:id', component: AuditTrail},
    {path: 'adverse-event/:id', component: AdverseEvent},
    {path: 'medication-event/:id', component: MedicationEvent},
    {path: 'questionnaires/new', component: Questionnaire},
    {path: 'questionnaires/preview', component: PreviewQuestionnaires},
    {path: 'questionnaires/:qid', component: Questionnaire},
    {path: 'questionnaires/:qid/answer/:id', component: QuestionnaireAnswer}
  ]
}
