import React from 'react'
import {Component} from 'react'

interface Props {
  object: object
  otherObject: object
  t?: (key: string, param?: object) => any
}

interface State {}

export default class Diff extends Component<Props, State> {
  getContent = object => {
    if (!object) {
      return this.props.t('noContent')
    }

    return JSON.stringify(object, undefined, 2)
  }

  render() {
    const {t, object, otherObject} = this.props

    return (
      <div className='row'>
        <div className='col-xs-6 original'>
          <label>{t('column.original')}</label>

          <div className='panel panel-default'>
            <pre className='json panel-body'>{this.getContent(object)}</pre>
          </div>
        </div>
        <div className='col-xs-6 updated'>
          <label>{t('column.updated')}</label>

          <div className='panel panel-default'>
            <pre className='json panel-body'>{this.getContent(otherObject)}</pre>
          </div>
        </div>
      </div>
    )
  }
}
