import React from 'react'
import BaseEventComponent, {BaseEventProps, BaseEventState} from '../../components/BaseEventComponent'
import SaveButton from '../../components/SaveButton'
import PeriodPicker from '../../components/PeriodPicker'
import TimePicker from '../../components/TimePicker'

import {withNamespaces} from 'react-i18next'
import {getFieldError} from '../../util/index'

import {connect} from 'react-redux'
import AdministrationEventModule from '../../modules/AdministrationEvent'
import {getAccessRights} from '../../modules/AccessRights'
import Period from '../../modules/Period'
import {navigate} from '../../modules/Location'
import AdministrationEventModel from '../../models/AdministrationEvent'
import PeriodModel from '../../models/Period'

interface Props extends BaseEventProps<AdministrationEventModel> {
  nextPeriod: PeriodModel
  getNextPeriod: (type) => any
}

interface State extends BaseEventState<AdministrationEventModel> {
  nextPeriodSuggested: boolean
}

class AdministrationEvent extends BaseEventComponent<AdministrationEventModel, Props, State> {
  constructor(props: Props) {
    super(props)
  }

  componentDidMount() {
    super.componentDidMount()

    const {modelId, getNextPeriod, type} = this.props

    if (!modelId) {
      getNextPeriod(type)
    }
  }

  componentDidUpdate(prevProps, _prevState) {
    super.componentDidUpdate(prevProps, _prevState)
    const {nextPeriod, model} = this.props

    if (nextPeriod.period && (!this.state.nextPeriodSuggested || prevProps.model !== model)) {
      const model = this.state.model
        .set('period', nextPeriod.period)
        .set('rescheduled', !!nextPeriod.get('rescheduled'))
      this.setState({
        model,
        modelWithoutChanges: model,
        nextPeriodSuggested: true
      } as State)
    }
  }

  getInputFields = () => {
    const {t, type} = this.props
    const localisationContext = type + 'Event'
    const {model} = this.state
    const errors = model.validate()

    return [
      (
        <div key='period-picker' className='row'>
          <div className='col-xs-12'>
            <PeriodPicker
              id='period'
              period={model.period}
              rescheduled={model.rescheduled}
              onPeriodChange={this.onFieldChange('period')}
              onRescheduledChange={this.onFieldChange('rescheduled')}
              readonly={model.locked}
              error={getFieldError('period', errors, model.error)}
              t={t}
            />
          </div>
        </div>
      ),
      (
        <div key='time-picker' className='row'>
          <div className='col-xs-12'>
            <TimePicker
              id='time'
              label={t(localisationContext + '.time')}
              time={model.time}
              showDateOnlyToggle={false}
              onChange={this.onTimeFieldChange('time', 'timeDateOnly')}
              readonly={model.locked}
              error={getFieldError('time', errors, model.error)}
              t={t}
            />
          </div>
        </div>
      )
    ]
  }

  getContent() {
    const {model} = this.state

    return (
      <div className='form-horizontal'>
        {this.getInputFields()}
        <SaveButton
          model={model}
          enabled={!!model.locked ? false : true}
          save={this.save}
          onModelSaved={this.onModelSaved}
          t={this.props.t}
        />
      </div>
    )
  }
}

const mapStateToProps = (
  {authenticatedUser, administrationEvent, accessRights, period},
  ownProps
) => {
  const {type} = ownProps
  const modelName = type + 'Event'
  const modelId = ownProps.params.id ? parseInt(ownProps.params.id, 10) : undefined

  return {
    modelId,
    modelName,
    authenticatedUser,
    model: administrationEvent,
    accessRights,
    nextPeriod: period
  }
}

const mapActionToProps = {
  getModel: AdministrationEventModule.getModel,
  resetModel: AdministrationEventModule.resetModelWithUserIdAndType,
  saveModel: AdministrationEventModule.saveModel,
  getNextPeriod: Period.getNextPeriod,
  getAccessRights,
  navigate
}

const mergeProps = (stateProps, dispatchProps, ownProps) => {
  const additionalProps = {
    wrapperClass: ownProps.type + '-event-view',
    backHref: '/administrations',
    forceBackHref: true,
    editUrl: `/event/edit-${ownProps.type}-event`
  }

  return Object.assign(additionalProps, ownProps, stateProps, dispatchProps, {
    resetModel: () => dispatchProps.resetModel(stateProps.authenticatedUser.id, ownProps.type)
  })
}

export default withNamespaces(['common'], {wait: true})(connect(
  mapStateToProps,
  mapActionToProps,
  mergeProps
)(AdministrationEvent))
