import BaseModel from './BaseModel'
import Question from './Question'
import QuestionAnswer from './QuestionAnswer'
import I18n from '../services/I18n'
import {List} from 'immutable'
import {accumulate, getTwoDigitLanguageCode, prefixKeys, reOrder} from '../util'
import _ from 'lodash'
import OrderedModel from './OrderedModel'
import QuestionnaireLanguage from './QuestionnaireLanguage'
import {LANGUAGE_STATUS} from '../config/constants'
import {ComponentType} from 'react'

// @TODO: include incomplete status inside language status enum
const incompleteStatus = 'incomplete'

export enum QuestionnaireStatus {
  draft = 'draft',
  published = 'published',
  disabled = 'disabled'
}

const constraints = {
  studyId: {
    presence: {allowEmpty: false}
  },
  period: {
    presence: {allowEmpty: false}
  },
  title: {
    presence: {allowEmpty: false}
  },
  questions: {
    presence: {allowEmpty: false},
    length: {
      minimum: 1
    }
  },
  languages: {
    presence: {allowEmpty: false},
    length: {
      minimum: 1
    }
  },
  status: {
    presence: {allowEmpty: false}
  }
}

const equalsKeys = null

const defaultValues = {
  studyId: undefined,
  order: undefined,
  period: undefined,
  title: undefined,
  description: undefined,
  questions: List<Question>(),
  multipleAnswers: false,
  languages: List<QuestionnaireLanguage>(),
  status: QuestionnaireStatus.draft,
  questionnaireHash: undefined
}

const constraintsByLanguage = lang => {
  return _.merge(
    {},
    constraints,
    {
      [`period.${lang}`]: {
        presence: {allowEmpty: false}
      },
      [`title.${lang}`]: {
        presence: {allowEmpty: false}
      }
    }
  )
}

const commonTranslationFields = ['title', 'period', 'description']

export interface TranslationDetails {
  field: string
  value: string
  page?: number
  order?: number
  type?: ComponentType
  localizationKey?: string
}

export default class Questionnaire
  extends BaseModel(defaultValues, equalsKeys, constraints)<Questionnaire>
  implements OrderedModel<Questionnaire> {
  studyId: number
  order: number
  period: any
  title: any
  description: any
  questions: List<Question>
  multipleAnswers: boolean
  languages: List<QuestionnaireLanguage>
  status: QuestionnaireStatus
  questionnaireHash: string

  constructor(js?: any) {
    super(js)

    return this.setListArray(
      [
        {questions: js => new Question(js)},
        {languages: js => new QuestionnaireLanguage(js)}
      ],
      js
    ) as Questionnaire
  }

  fromJS(js: any): Questionnaire {
    return new Questionnaire(js)
  }

  getPeriod() {
    return this.getLocalizedKey('period')
  }

  getTitle() {
    return this.getLocalizedKey('title')
  }

  getDescription() {
    return this.getLocalizedKey('description')
  }

  getDisplayName() {
    return `${this.getPeriod()} - ${this.getTitle()}`
  }

  getAnswerQuestion(answer: QuestionAnswer) {
    const {order, page} = answer

    return this.questions.find(q => q.order === order && q.page === page)
  }

  getQuestions(): List<Question> {
    return this.get('questions') as List<Question>
  }

  getPageQuestions(page: number): List<Question> {
    return this.getQuestions()
      .filter(q => q.page === page)
      .sort((a, b) => a.order - b.order) as List<Question>
  }

  getQuestion(page: number, order: number): Question {
    return this.getQuestions()
      .find(q => q.page === page && q.order === order)
  }

  updateQuestion(question: Question) {
    const list = this.getQuestions()
    const current = list.find(q => q.identityEquals(question))

    if (current) {
      const index = list.indexOf(current)

      return this.setQuestions(list.set(index, question))
    }

    throw new Error('No existing model found with id ' + question.getId())
  }

  setQuestions(questions: List<Question>) {
    return this.set('questions', questions) as Questionnaire
  }

  addQuestion(language: string, page: number) {
    const list = this.getQuestions()
    const lastEntry = list.filter(q => q.page === page).last()
    const order = lastEntry ? lastEntry.getOrder() + 1 : 1

    const question = new Question({
      title: {
        [language]: ''
      },
      page,
      order,
      components: []
    })

    return this.setQuestions(list.push(question))
  }

  deleteQuestion(question: Question) {
    const list = this.getQuestions()
      .filter(q => !q.identityEquals(question))
      .map(q => {
        if (q.page !== question.page || q.order < question.order) {
          return q
        }

        return q.setOrder(q.order - 1)
      }) as List<Question>

    return this.setQuestions(list)
  }

  duplicateQuestion(question: Question) {
    const list = this.getQuestions().map(q => {
      if (q.page !== question.page || q.order <= question.order) {
        return q
      }

      return q.setOrder(q.order + 1)
    }) as List<Question>

    return this.setQuestions(list.push(question.duplicateWithOrder(question.order + 1)))
  }

  getPages(): number[] {
    return Array.from(
      new Set(
        this.getQuestions()
          .map((q: Question): number => q.page)
          .toArray()
      )
    ).sort()
  }

  getLastPage(): number {
    return _.max(this.getPages())
  }

  addPage(language: string) {
    const lastPage = this.getLastPage()
    const newQuestions = this.getQuestions().push(
      new Question({
        page: lastPage ? lastPage + 1 : 1,
        order: 1,
        title: {
          [language]: ''
        },
        components: []
      })
    )

    return this.setQuestions(newQuestions)
  }

  deletePage(page: number) {
    const newQuestions = this.getQuestions()
      .filter(question => question.page !== page)
      .map(question => {
        const pageQuestion = question.page
        return pageQuestion > page ? question.setPage(pageQuestion - 1) : question
      }) as List<Question>

    return this.setQuestions(newQuestions)
  }

  setOrder(order: number) {
    return this.set('order', order) as Questionnaire
  }

  getOrder(): number {
    return this.order
  }

  getDefaultLanguage() {
    return this.languages.find((language) => language.default)
  }

  setDefaultLanguage(language: string) {
    let languages = this.getLanguages()
    const isLanguageExist = languages.some((ql) => (ql.language === language))

    if (!isLanguageExist) {
      languages = languages.push(
        new QuestionnaireLanguage({language, default: true, status: LANGUAGE_STATUS.PUBLISHED})
      )
    }

    languages = languages.map((ql) => {
      return ql.language === language ? ql.setDefault(true) : ql.setDefault(false)
    }) as List<QuestionnaireLanguage>

    return this.set('languages', languages) as Questionnaire
  }

  getDefaultLanguageCode() {
    const defaultLanguage = this.getDefaultLanguage()

    return defaultLanguage && defaultLanguage.language
  }

  getResolvedLanguage(userLanguage: string) {

    const containUserLanguage = this.languages.some(lang => lang.isPublished() && lang.language === userLanguage)

    return containUserLanguage ? userLanguage : this.getDefaultLanguageCode()
  }

  getLanguages() {
    return this.get('languages')
  }

  getSortedLanguages() {
    return List([this.getDefaultLanguage()])
      .concat(this.getSortedAdditionalLanguages())
  }

  hasPublishedLanguage(lang: string) {
    return this.getLanguages().some(l => l.language === lang && l.isPublished())
  }

  setAdditionalLanguages(languages: string[]) {

    const defaultLanguageCode = this.getDefaultLanguageCode()
    const existingLanguages = this.getLanguages()
      .filter(ql => ql.language === defaultLanguageCode || languages.indexOf(ql.language) !== -1)
    const existingLanguageCodes = existingLanguages.map(ql => ql.language)
    const newLanguages = languages
      .filter(language => existingLanguageCodes.indexOf(language) === -1)
      .map(language => new QuestionnaireLanguage({
          language,
          status: LANGUAGE_STATUS.DRAFT
        })
      )
    const updatedLanguages = existingLanguages.concat(newLanguages)

    return this.set('languages', updatedLanguages)
  }

  getAdditionalLanguages() {
    return this.getLanguages().filter(l => !l.default)
  }

  getSortedAdditionalLanguages() {
    return this.getAdditionalLanguages()
      .sortBy(l => l.language)
  }

  getLanguage(lang: string) {
    return this.getLanguages().find(l => l.language === lang)
  }

  updateLanguage(language: QuestionnaireLanguage) {

    const list = this.getLanguages()
    const current = list.find((m) => language.identityEquals(m))

    if (current) {

      const index = list.indexOf(current)

      return this.set('languages', list.set(index, language.setIdentityFrom(current)))

    } else {

      throw new Error('No existing model found with id ' + language.getId())
    }
  }

  getLanguageTranslations(language: string): TranslationDetails[] {

    const commonTranslations = commonTranslationFields.map(field => ({
      field,
      value: this.getField(field, language)
    }))

    return this.getQuestions()
      .map(question => question.getLanguageTranslations(language))
      .toArray()
      .reduce(accumulate, commonTranslations)
  }

  getField(key: string, lang: string) {
    const field = this.get(key)

    switch (key) {
      case 'title':
      case 'period':
      case 'description':
        return field && field[lang] ? field[lang] : ''
      default:
        return field
    }
  }

  setField(key: string, value: any, lang: string) {
    const oldValue = this.get(key)
    let newValue

    switch (key) {
      case 'title':
      case 'period':
      case 'description':
        newValue = {...oldValue, [lang]: value}
        break
      default:
        newValue = value
    }
    return this.set(key, newValue) as Questionnaire
  }

  reOrderQuestions(page: number, srcOrder: number, dstOrder: number) {

    const newQuestions = this.getQuestions().map((q: Question) => {

      if (q.page !== page) {
        return q
      }

      return reOrder(q, srcOrder, dstOrder)

    }) as List<Question>

    return this.setQuestions(newQuestions)
  }

  validateByLanguage(lang: string) {

    const questionnaireErrors = this._validate(lang ? constraintsByLanguage(lang) : constraints)
    const questionErrors = this.validateQuestionsByLanguage(lang)
    const missingTranslationErrors = this.validateMissingTranslations(lang)
    const combinedErrors = _.merge({}, questionnaireErrors, questionErrors, missingTranslationErrors)

    return !_.isEmpty(combinedErrors) ? combinedErrors : undefined
  }

  validate() {

    const defaultLanguageErrors = this.validateByLanguage(this.getDefaultLanguageCode())
    const additionalLanguageErrors = this.validateAdditionalLanguages()
    const combinedErrors = _.merge({}, defaultLanguageErrors, additionalLanguageErrors)

    return !_.isEmpty(combinedErrors) ? combinedErrors : undefined
  }

  cleanupTranslations() {

    const defaultLanguage = this.getDefaultLanguageCode()
    const additionalLanguages = this.getAdditionalLanguages().map(l => l.language).toArray()
    let model = this as Questionnaire
    const languages = [defaultLanguage].concat(additionalLanguages || [])

    commonTranslationFields.forEach(field => {

      let fieldLocalization = model.get(field)

      if (fieldLocalization) {

        const obsoleteLanguages = Object.keys(fieldLocalization).filter(lang => !_.includes(languages, lang))
        fieldLocalization = _.omit(fieldLocalization, obsoleteLanguages)

        if (_.isEmpty(fieldLocalization[defaultLanguage])) {
          additionalLanguages.forEach(additionalLanguage => {
            fieldLocalization[additionalLanguage] = undefined
          })
        }

        model = model.set(field, fieldLocalization) as Questionnaire
      }
    })

    return model.setQuestions(
      model.getQuestions().map(q => q.cleanupTranslations(defaultLanguage, additionalLanguages)) as List<Question>
    )
  }

  getResolvedLanguageStatus(questionnaireLanguage: QuestionnaireLanguage) {

    const isDraft = questionnaireLanguage.status === LANGUAGE_STATUS.DRAFT

    return isDraft && !!this.validateByLanguage(questionnaireLanguage.language)
      ? incompleteStatus
      : questionnaireLanguage.status
  }

  toggleStatus() {
    switch (this.status) {
      case QuestionnaireStatus.draft:
        return this.set('status', QuestionnaireStatus.published)
      case QuestionnaireStatus.published:
        return this.set('status', QuestionnaireStatus.disabled)
      case QuestionnaireStatus.disabled:
        return this.set('status', QuestionnaireStatus.published)
      default:
        return this
    }
  }

  isPublished() {
    return this.status === QuestionnaireStatus.published
  }

  private validateMissingTranslations(lang) {

    const defaultLanguage = this.getDefaultLanguageCode()

    if (lang === defaultLanguage) {
      return undefined
    }

    const defaultLanguageTranslations = this.getLanguageTranslations(defaultLanguage)
    const otherLanguageTranslations = this.getLanguageTranslations(lang)
    const translationErrors = defaultLanguageTranslations
      .filter(l => l.value)
      .map(dl => {

        const otherLanguageTranslation = otherLanguageTranslations.find(ol => ol.field === dl.field
          && ol.page === dl.page
          && ol.order === dl.order
          && ol.localizationKey === dl.localizationKey
        )

        if (!otherLanguageTranslation || _.isEmpty(otherLanguageTranslation.value)) {
          return {[`localization.${lang}`]: [`Localization missing for value ${dl.value}`]}
        }
      })
      .filter(errors => !_.isEmpty(errors))
      .reduce((accu, value) => _.merge(accu, value), {})

    return !_.isEmpty(translationErrors) ? translationErrors : undefined
  }

  private getLocalizedKey(key) {
    const language = getTwoDigitLanguageCode(I18n.language)

    if (this[key]) {
      return this[key][language] ? this[key][language] : this[key][Object.keys(this[key])[0]]
    }

    return I18n.t('questionnaire.noLocalization', {key, language})
  }

  private validateQuestionsByLanguage(lang: string) {

    return this.questions
      .map((question, index) => prefixKeys(`questions[${index}]`, question.validateByLanguage(lang)))
      .filter(errors => !_.isEmpty(errors))
      .toArray()
      .reduce((accu, value) => _.merge(accu, value), {})
  }

  private validateAdditionalLanguages() {

    return this.getAdditionalLanguages()
      .filter(l => l.isPublished())
      .map(l => this.validateByLanguage(l.language))
      .filter(errors => !_.isEmpty(errors))
      .toArray()
      .reduce((accu, value) => _.merge(accu, value), {})
  }
}
