import BaseModel from './BaseModel'
import {fromJS} from 'immutable'
import Question from './Question'
import _ from 'lodash'
import {validate} from 'validate.js'

const constraints = {
  order: {
    presence: {allowEmpty: false}
  },
  page: {
    presence: {allowEmpty: false}
  },
  value: {
    presence: {allowEmpty: true}
  }
}

const equalsKeys = ['order', 'page']

const defaultValues = {
  order: undefined,
  page: undefined,
  value: fromJS({})
}

export default class QuestionAnswer
  extends BaseModel(defaultValues, equalsKeys, constraints)<QuestionAnswer> {
  order: number
  page: number
  value: any

  constructor(js?: any) {
    super(js)

    if (js.value) {
      return this.set('value', fromJS(js.value)) as QuestionAnswer
    }
  }

  fromJS(js: any): QuestionAnswer {
    return new QuestionAnswer(js)
  }

  getFieldValue(field: string) {
    if (this.value) {
      return this.value.get(field)
    }

    return null
  }

  validateWithQuestion(question: Question) {
    const errors = super.validate()

    if (errors || !question) {
      return errors
    }

    const valueConstraints = question.components
      .filter(component => !component.isInstruction() && component.isMandatory())
      .map(component => component.getField())
      .reduce((accu, field) => {
        accu[field] = {
          presence: {allowEmpty: false}
        }

        return accu
      }, {})

    if (_.isEmpty(valueConstraints)) {
      return null
    }

    return validate(this.value.toJS(), valueConstraints, {fullMessages: false})
  }
}
