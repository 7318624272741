import Questionnaire from './Questionnaire'
import Collection from './ImmutableCollection'
import {List} from 'immutable'
import {reOrder} from '../util'

export default class Questionnaires extends Collection(Questionnaire)<Questionnaire> {

  reOrderQuestionnaires(srcOrder: number, dstOrder: number) {

    const updatedList = this.list.map((q: Questionnaire) => reOrder(q, srcOrder, dstOrder)) as List<Questionnaire>

    return this.set('list', updatedList)
  }

  findNextQuestionnaire(currentOrder: number) {
    return this.list.filter((q) => q.order > currentOrder)
      .sortBy((q) => q.order)
      .first()
  }

  findPrevQuestionnaire(currentOrder: number) {
    return this.list.filter((q) => q.order < currentOrder)
      .sortBy((q) => q.order)
      .last()
  }
}
