/**
 * Created by Mauritz Untamala on 10/12/15.
 */
import React from 'react'
import {PureComponent} from 'react'
import Details from './components/Details'
import Diff from './components/Diff'
import SubPageView from '../../../components/SubPageView'

import {WithNamespaces, withNamespaces} from 'react-i18next'

import {connect} from 'react-redux'
import Audit from '../../../modules/Audit'
import AuditTrailModel from '../../../models/AuditTrail'

import './AuditTrail.less'

interface Props extends WithNamespaces {
  auditId: number
  audit: AuditTrailModel
  getAudit: (id, queryParams?) => any
}

interface State {}

class AuditTrail extends PureComponent<Props, State> {
  componentDidMount() {
    const {getAudit, auditId} = this.props
    getAudit(auditId)
  }

  componentDidUpdate(prevProps) {
    const {auditId, getAudit} = this.props

    if (prevProps.auditId !== auditId) {
      getAudit(auditId)
    }
  }

  content = () => {
    const {t, audit} = this.props

    if (audit.isLoading) {
      return <div key='audit-trail-loading'>{t('loading')}</div>
    }

    return (
      <div className='audit-trail-view'>
        <h1>{t('auditTrailView.title')}</h1>
        <Details t={t} audit={audit}/>
        <Diff t={t} object={audit.original} otherObject={audit.updated}/>
      </div>
    )
  }

  render() {
    const navItems = [{label: 'back', href: '/admin/audit-trails', className: 'back-navigation'}]

    return <SubPageView navItems={navItems} t={this.props.t}>{this.content()}</SubPageView>
  }
}

const mapStateToProps = ({auditTrail}, ownProps) => {
  const {id} = ownProps.params
  const auditId = id ? parseInt(id, 10) : null

  return {
    audit: auditTrail,
    auditId
  }
}

const mapActionToProps = {
  getAudit: Audit.getModel
}

export default withNamespaces(['common'], {wait: true})(connect(
  mapStateToProps,
  mapActionToProps
)(AuditTrail))
