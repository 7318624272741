import React from 'react'
import {PureComponent} from 'react'
import Select from 'react-select'
import SubPageView from '../../../components/SubPageView'

import classNames from 'classnames'
import {WithNamespaces, withNamespaces} from 'react-i18next'
import I18n from '../../../services/I18n'

import {connect} from 'react-redux'
import {navigate} from '../../../modules/Location'
import QuestionnaireAnswersModule from '../../../modules/QuestionnaireAnswers'
import QuestionnairesModule from '../../../modules/Questionnaires'
import QuestionnairesModel from '../../../models/Questionnaires'
import QuestionnaireAnswers from '../../../models/QuestionnaireAnswers'

import './Questionnaires.less'

interface Props extends WithNamespaces {
  questionnaires: QuestionnairesModel
  questionnaireAnswers: QuestionnaireAnswers
  getQuestionnaires: (queryParams?, reset?) => any
  getQuestionnaireAnswers: (queryParams?, reset?) => any
  navigate: (url: string, silent?: boolean) => any
}

interface State {
  questionnaireId: number
}

class Questionnaires extends PureComponent<Props, State> {
  constructor(props: Props) {
    super(props)
    this.state = {
      questionnaireId: null
    }
  }

  componentDidMount() {
    this.props.getQuestionnaires({lang: I18n.language})
    this.props.getQuestionnaireAnswers()
  }

  componentDidUpdate() {
    const {questionnaires, questionnaireAnswers} = this.props

    if (
      this.state.questionnaireId === null &&
      !questionnaires.list.isEmpty() &&
      !questionnaireAnswers.isLoading &&
      !questionnaires.isLoading
    ) {
      const firstUnansweredQuestion = questionnaires.list
        .filter(questionnaire => !questionnaireAnswers.getAnswerForQuestionnaire(questionnaire.id))
        .first()

      if (firstUnansweredQuestion) {
        this.setState({questionnaireId: firstUnansweredQuestion.id})
      }
    }
  }

  getQuestionnaireOptions = () => {
    const {questionnaires, questionnaireAnswers} = this.props

    return questionnaires.list
      .filter(
        questionnaire =>
          questionnaire.hasPublishedLanguage(I18n.language) &&
          (questionnaire.multipleAnswers || !questionnaireAnswers.getAnswerForQuestionnaire(questionnaire.id))
      )
      .map(questionnaire => {
        return {
          label: questionnaire.getDisplayName(),
          value: questionnaire.id
        }
      })
      .toArray()
  }

  onQuestionnaireChange = selectedOption => {
    const questionnaireId = selectedOption.value

    this.setState({questionnaireId})
  }

  getCurrentSelectedOption = (options) => options.find(({value}) => value === this.state.questionnaireId)

  getInputFields = () => {
    const {t} = this.props

    const options = this.getQuestionnaireOptions()
    const option = this.getCurrentSelectedOption(options)

    return [
      (
        <div key='questionnaire-picker' className='form-group'>
          <div className='col-xs-12'>
            <Select key='questionnaire_select'
                    name='questionnaire'
                    isMulti={false}
                    isClearable={true}
                    isSearchable={false}
                    value={option}
                    options={options}
                    onChange={this.onQuestionnaireChange}
                    placeholder={t('questionnaireAnswer.questionnaire')}
                    noOptionsMessage={() => t('noOptions')}/>
          </div>
        </div>
      )
    ]
  }

  onStart = () => {
    const {questionnaireId} = this.state

    this.props.navigate(`/questionnaires/${questionnaireId}/answer`)
  }

  getStartButton = () => {
    const {questionnaireId} = this.state

    return (
      <button
        key='label'
        className={classNames('btn', 'btn-default', 'btn-lg', 'save-btn')}
        onClick={this.onStart}
        disabled={!questionnaireId}>
        {this.props.t('button.start')}
      </button>
    )
  }

  renderSelectQuestionnaire() {
    return (
      <div className='questionnaire-select-view'>
        <h1>{this.props.t('questionnairesViewTitle')}</h1>
        <p>{this.props.t('questionnairesViewHelp')}</p>
        <div className='form-horizontal'>
          {this.getInputFields()}
          {this.getStartButton()}
        </div>
      </div>
    )
  }

  render() {
    const navItems = [{label: 'back', href: '/questionnaires', className: 'back-navigation'}]

    return (
      <SubPageView navItems={navItems} t={this.props.t}>
        {this.renderSelectQuestionnaire()}
      </SubPageView>
    )
  }
}

const mapActionToProps = {
  getQuestionnaires: QuestionnairesModule.getModels,
  getQuestionnaireAnswers: QuestionnaireAnswersModule.getModels,
  navigate
}

const mapStateToProps = ({questionnaires, questionnaireAnswers}, _ownProps) => {
  return {
    questionnaires,
    questionnaireAnswers
  }
}

export default withNamespaces(['common'], {wait: true})(connect(
  mapStateToProps,
  mapActionToProps
)(Questionnaires))
