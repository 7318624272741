import ModelModule from './ModelModule'
import AdverseEvent from '../models/AdverseEvent'
import {bind} from '../util'
import moment from 'moment'

class AdverseEventModule extends ModelModule<AdverseEvent> {
  constructor() {
    super('event/adverse', 'adverseEvent')
    bind(this, this.resetModelWithUserId)
  }

  protected getInitialState = () => new AdverseEvent()

  public resetModelWithUserId(userId) {
    return (dispatch) => dispatch(this.resetModelAction(userId))
  }

  /**
   * @override
   */
  protected getAdditionalActionHandlers() {
    return {
      [this.resetModelType]: (_state, {payload}) =>
        this.getInitialState().set('personId', payload).set('started', moment())
    }
  }
}

export default new AdverseEventModule()
