/**
 * Created by Mauritz Untamala on 24/09/15.
 */
import React from 'react'
import {Component} from 'react'
import MessageCountView from './MessageCountView'
import {Nav, Navbar, NavDropdown, NavItem} from 'react-bootstrap'
import Select from 'react-select'

import _ from 'lodash'
import {WithNamespaces, withNamespaces} from 'react-i18next'

import {connect} from 'react-redux'
import {push} from 'react-router-redux'
import {navigate} from '../modules/Location'
import AuthenticatedUser from '../modules/AuthenticatedUser'
import SiteStudy from '../modules/SiteStudy'
import User from '../models/User'
import {NavItems} from '../models/App'

const messagesRegExp = new RegExp('messages')

interface Props extends WithNamespaces {
  navItems: NavItems
  currentHref: string
  user: User
  selectedSiteStudyId: number
  getSiteStudy: (id, queryParams?) => any
  updateSelectedSiteStudyId: (siteStudyId) => any
  navigate: (url: string, silent?: boolean) => any
}

interface State {
  navBarExpanded: boolean
}

class NavigationView extends Component<Props, State> {
  constructor(props: Props) {
    super(props)

    this.state = {
      navBarExpanded: false
    }
  }

  componentDidMount() {
    const {selectedSiteStudyId, getSiteStudy} = this.props

    if (selectedSiteStudyId) {
      getSiteStudy(selectedSiteStudyId)
    }
  }

  componentDidUpdate(prevProps) {
    const {selectedSiteStudyId, getSiteStudy} = this.props

    if (selectedSiteStudyId && prevProps.selectedSiteStudyId !== selectedSiteStudyId) {
      getSiteStudy(selectedSiteStudyId)
    }
  }

  render() {
    const {children} = this.props

    return (
      <div key='navigation-view' className='navigation-view'>
        <Navbar
          key='navbar'
          fluid={true}
          fixedTop={true}
          expanded={this.state.navBarExpanded}
          onToggle={this.touchNavBar}
        >
          <Navbar.Header>
            <Navbar.Toggle/>
          </Navbar.Header>
          <Navbar.Collapse key='collapsible'>
            {this.getLeftNav()}
            {this.getRightNav()}
          </Navbar.Collapse>
        </Navbar>
        {children}
      </div>
    )
  }

  hideNavBar = () => {
    this.setState({navBarExpanded: false})
  }

  touchNavBar = () => {
    this.setState({navBarExpanded: !this.state.navBarExpanded})
  }

  onLinkClick(href) {
    this.props.navigate(href)
    this.hideNavBar()
  }

  createNavItem = (navItem, className?) => {
    const subviews = []
    const key = 'navigation.' + navItem.label

    subviews.push(this.props.t(key))

    if (messagesRegExp.test(navItem.href)) {
      subviews.push(<MessageCountView key='message-count-view'/>)
    }

    return (
      <NavItem
        key={'navitem-' + navItem.href}
        className={className}
        active={navItem.href === this.props.currentHref && !!navItem.href}
        onSelect={() => this.onLinkClick(navItem.href)}>
        {subviews}
      </NavItem>
    )
  }

  mapToNavItems = (navItems, className?) => {
    return navItems.map(navItem => this.createNavItem(navItem, className)).toArray()
  }

  onSelectSiteStudy = seletedOption => {
    const {updateSelectedSiteStudyId} = this.props
    updateSelectedSiteStudyId(seletedOption ? seletedOption.value : null)
    this.hideNavBar()
  }

  getSelectedSiteStudyNavItem = () => {
    const {t, selectedSiteStudyId} = this.props

    const options = this.getSelectableSiteStudyOptions()

    return (
      <li key='selected-study-nav' className='dropdown'>
        <Select className='select-site-study'
                name='select-site-study'
                isDisabled={false}
                isClearable={true}
                isMulti={false}
                options={options}
                value={options.find(o => o.value === selectedSiteStudyId)}
                onChange={this.onSelectSiteStudy}
                placeholder={t('navigation.selectSiteStudy')}
                noOptionsMessage={() => t('noOptions')}/>
      </li>
    )
  }

  getSelectableSiteStudyOptions = () => {

    const {selectableSiteStudies} = this.props.navItems

    const options = selectableSiteStudies
      .map(({id, studyName, number}) => ({
        label: `${studyName} - ${number}`,
        value: id
      }))
      .toArray()

    return _.sortBy(options, (option) => option.label.toLowerCase())
  }

  getNavItemForKey = key => {
    const navItems = this.props.navItems
    const labelEqualsKey = ({label}) => label === key
    const navItem =
      navItems.default.find(labelEqualsKey) || navItems.siteStudy.find(labelEqualsKey)

    if (navItem) {
      return this.createNavItem(navItem)
    }
  }

  buildNavItems = key => {
    if (_.isObject(key)) {
      const subItems = _.compact(_.map(key.items, this.buildNavItems))

      if (subItems.length > 0) {
        const title = key.labelValue ? key.labelValue : this.props.t('navigation.' + key.label)

        return (
          <NavDropdown
            className={key.class}
            key={key.label + '-dropdown'}
            title={title}
            id='nav-dropdown'>
            {subItems}
          </NavDropdown>
        )
      }
    } else if (key === 'site-study-selection') {
      return this.getSelectedSiteStudyNavItem()
    } else {
      return this.getNavItemForKey(key)
    }
  }

  getStudyMenu = () => {
    return {
      label: 'study',
      items: ['studyInfo', 'subjects', 'studyGroups', 'reports', 'reminders', 'questionnaires']
    }
  }

  getProfileMenu = className => {
    return {
      labelValue: this.props.user.visibleName(),
      class: className,
      items: ['profile', 'logout']
    }
  }

  getAdminMenu = () => {
    return {
      label: 'admin',
      items: ['studies', 'sites', 'personnel', 'auditTrails', 'trackers']
    }
  }

  getLeftNav = () => {
    if (this.props.user.isPersonnel()) {
      return this.getPersonnelNavigationItems()
    }

    return this.getSubjectNavigationItems()
  }

  getSubjectNavigationItems = () => {
    const {user, navItems, t} = this.props

    const navItemComponents = this.mapToNavItems(
      navItems.default.filter(({label}) => label !== 'logout')
    )

    navItemComponents.push(
      <NavItem className='hidden-lg hidden-md' key='navitem-logout' href='/logout'>
        {t('navigation.logout')}
      </NavItem>
    )

    return [
      <Navbar.Text key='navitem-username' className='navbar-text username'>{user.visibleName()}</Navbar.Text>,
      <Nav key='left-nav' navbar={true}>{navItemComponents}</Nav>
    ]
  }

  getPersonnelNavigationItems = () => {
    const navItemComponents = [
      'adminMessages',
      this.getAdminMenu(),
      this.getStudyMenu(),
      'site-study-selection'
    ].map(this.buildNavItems)

    navItemComponents.push(
      this.buildNavItems(this.getProfileMenu('hidden-lg hidden-md logged-username'))
    )

    return [
      <Nav key='left-nav' navbar={true}>{navItemComponents}</Nav>
    ]
  }

  getRightNav = () => {
    const getNavItems = () => {
      if (this.props.user.isSubject()) {
        return (
          <NavItem key='navitem-logout' onClick={() => this.onLinkClick('/logout')}>
            <i className='fa fa-sign-out'/>
          </NavItem>
        )
      } else {
        return this.buildNavItems(this.getProfileMenu('logged-username'))
      }
    }

    return (
      <ul key='right-nav' className='nav navbar-nav pull-right hidden-xs hidden-sm'>
        {getNavItems()}
      </ul>
    )
  }
}

const mapActionCreators = {
  push,
  navigate,
  updateSelectedSiteStudyId: AuthenticatedUser.updateSelectedSiteStudyId,
  getSiteStudy: SiteStudy.getModel
}

const mapStateToProps = ({authenticatedUser, siteStudy, app}, _ownProps) => {
  return {
    user: authenticatedUser,
    selectedSiteStudyId: authenticatedUser.selectedSiteStudyId,
    navItems: app.navItems,
    siteStudy
  }
}

export default withNamespaces(['common'], {wait: true})(
  connect(
    mapStateToProps,
    mapActionCreators
  )(NavigationView)
)
