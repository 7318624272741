import React from 'react'
import BaseDownloadComponent, {BaseProps, BaseState} from '../../../components/BaseDownloadComponent'
import {DragDropContext, Droppable, DroppableProvided, DroppableStateSnapshot} from 'react-beautiful-dnd'
import QuestionnaireListItem from './QuestionnaireListItem'
import Dropzone from 'react-dropzone'

import {connect} from 'react-redux'
import Questionnaires from '../../../modules/Questionnaires'
import QuestionnairesModel from '../../../models/Questionnaires'
import Questionnaire from '../../../modules/Questionnaire'
import {navigate} from '../../../modules/Location'
import {error} from '../../../modules/Notifications'
import User from '../../../models/User'
import {downloadResource, pollDownload} from '../../../modules/Download'
import Criteria from '../../../models/Criteria'

import {withNamespaces} from 'react-i18next'
import {List} from 'immutable'
import _ from 'lodash'
import qs from 'qs'

import './Questionnaires.less'

const ZipMimeTypes = 'application/zip,application/octet-stream,application/x-zip-compressed,multipart/x-zip'

interface Props extends BaseProps {
  authenticatedUser: User,
  questionnaires: QuestionnairesModel
  navigate: (url) => any
  getQuestionnaires: (id, reset) => any
  exportQuestionnaires: (ids: List<number>) => any
  deleteQuestionnaire: (model) => any
  changeOrder: (questionnaireId, srcOrder, dstOrder) => any
  importQuestionnaires: (file) => any
}

interface State extends BaseState {
  criteria: Criteria
  holdingShift: boolean
  lastSelectedIdx: number
}

const draggingListBackground = '#f0f0f0'

const getListStyle = isDraggingOver => ({
  background: isDraggingOver ? draggingListBackground : undefined
})

const shiftKey = 'Shift'

const getIndexRange = (start: number, end: number): number[] => {

  if (start <= end) {
    return _.range(start, end + 1)
  }

  return _.range(end, start + 1)
}

const defaultCriteria = new Criteria({ids: []})

class QuestionnairesView extends BaseDownloadComponent<Props, State> {

  constructor(props: Props) {
    super(props)

    this.state = {
      download: undefined,
      pollerId: undefined,
      criteria: defaultCriteria,
      holdingShift: false,
      lastSelectedIdx: undefined
    }
  }

  componentDidMount() {

    const {getQuestionnaires} = this.props

    getQuestionnaires(null, true)

    window.addEventListener('keydown', this.onKeyDown)
    window.addEventListener('keyup', this.onKeyUp)
  }

  componentDidUpdate(prevProps: Props, prevState: State) {

    super.componentDidUpdate(prevProps, prevState)

    const {authenticatedUser, getQuestionnaires} = this.props

    if (authenticatedUser.selectedSiteStudyId !== prevProps.authenticatedUser.selectedSiteStudyId) {

      this.setState({criteria: defaultCriteria, lastSelectedIdx: undefined})
      getQuestionnaires(null, true)
    }
  }

  componentWillUnmount() {

    super.componentWillUnmount()

    window.removeEventListener('keydown', this.onKeyDown)
    window.removeEventListener('keyup', this.onKeyUp)
  }

  getSortedQuestionnaires = () => {
    return this.props.questionnaires.list.sort((e1, e2) => e1.order - e2.order)
  }

  isSelected = (idx: number): boolean => {
    return this.state.criteria.ids.contains(idx)
  }

  onKeyDown = (event: KeyboardEvent) => {

    if (event.key === shiftKey) {
      this.setState({holdingShift: true})
    }
  }

  onKeyUp = (event: KeyboardEvent) => {

    if (event.key === shiftKey) {
      this.setState({holdingShift: false})
    }
  }

  getSelectedQuestionnairesAfterSelection = (idx: number, isSelected: boolean) => {

    const {criteria, holdingShift, lastSelectedIdx} = this.state
    const selectedQuestionnaires = criteria.ids

    if (holdingShift && !isSelected && !_.isNil(lastSelectedIdx)) {

      const addition = _.difference(
        getIndexRange(idx, lastSelectedIdx),
        selectedQuestionnaires.toArray()
      )

      return selectedQuestionnaires.push(...addition)
    }

    if (isSelected) {

      return selectedQuestionnaires.filter(item => item !== idx) as List<number>
    }

    return selectedQuestionnaires.push(idx)
  }

  onSelectionChange = (idx: number) => {

    const isSelected = this.isSelected(idx)
    const criteria = this.state.criteria.setIds(this.getSelectedQuestionnairesAfterSelection(idx, isSelected))
    const lastSelectedIdx = isSelected ? undefined : idx

    this.setState({criteria, lastSelectedIdx})
  }

  getListItems = () => {
    const {navigate, deleteQuestionnaire, t} = this.props

    return this.getSortedQuestionnaires().map((questionnaire, index) => (
      <QuestionnaireListItem
        key={`questionnaire_item_${questionnaire.id}`}
        index={index}
        questionnaire={questionnaire}
        selected={this.isSelected(index)}
        onSelectionChange={this.onSelectionChange}
        navigate={navigate}
        deleteQuestionnaire={deleteQuestionnaire}
        t={t}
      />
    ))
  }

  getSelectedQuestionnaireIds = (): List<number> => {

    const {questionnaires} = this.props
    const {criteria} = this.state

    return criteria.ids.map((qIdx) => questionnaires.list.get(qIdx).id) as List<number>
  }

  onAddClick = () => {
    const {navigate} = this.props
    navigate(`/admin/questionnaires/new`)
  }

  renderImportButton = () => {
    const {t, importQuestionnaires} = this.props
    const content = ({getRootProps, getInputProps}) => (
      <button
        {...getRootProps({
          className: 'dropzone btn btn-default import-questionnaire'
        })}>
        <input {...getInputProps()} />
        <i className='fa fa-upload '/>
        {t('questionnaire.import.title')}
      </button>
    )

    const onDropAccepted = (files) => {
      importQuestionnaires(files[0])
    }

    const onDropRejected = (_files) => {
      error(this.props.t('questionnaire.import.error'))
    }

    return (
      <Dropzone multiple={false}
                accept={ZipMimeTypes}
                onDropAccepted={onDropAccepted}
                onDropRejected={onDropRejected}>{content}</Dropzone>
    )
  }
  onExportClick = () => {

    this.props.exportQuestionnaires(this.getSelectedQuestionnaireIds())
  }

  onPreviewClick = () => {

    const queryParams = this.state.criteria
      .setIds(this.getSelectedQuestionnaireIds())
      .getQueryParams()
    const urlParams = _.isEmpty(queryParams) ? '' : `?${qs.stringify(queryParams)}`

    this.props.navigate(`/admin/questionnaires/preview${urlParams}`)
  }

  onDragEnd = (result) => {

    if (!result.destination) {
      return
    }

    const {changeOrder, questionnaires} = this.props
    const srcIdx = result.source.index
    const dstIdx = result.destination.index

    if (srcIdx !== dstIdx) {

      const srcQuestionnaire = questionnaires.getModelByIndex(srcIdx)
      const dstQuestionnaire = questionnaires.getModelByIndex(dstIdx)

      const {id, order} = srcQuestionnaire

      changeOrder(id, order, dstQuestionnaire.order)
    }
  }

  renderHeaderButtons = () => {

    const {t} = this.props
    const {criteria} = this.state
    const isActionDisabled = criteria.ids.size < 1

    return (
      <div className='pull-right header-btns'>
        <button className='add-questionnaire'
                onClick={this.onAddClick}>
          {t('questionnaire.add')}
        </button>
        {this.renderImportButton()}
        <button className='export-questionnaires'
                disabled={isActionDisabled}
                onClick={this.onExportClick}>
          <i className='fa fa-download export'/> {t('questionnaire.export')}
        </button>

        <button className='preview-questionnaires'
                disabled={isActionDisabled}
                onClick={this.onPreviewClick}>
          <i className='fa fa-search preview'/> {t('questionnaire.tabs.preview')}
        </button>
      </div>
    )
  }

  render() {
    const {t} = this.props
    const droppableContent = (provided: DroppableProvided, snapshot: DroppableStateSnapshot) => {
      return (
        <ul ref={provided.innerRef} className='list-group' style={getListStyle(snapshot.isDraggingOver)}>
          {this.getListItems()}
        </ul>
      )
    }

    return (
      <div className='questionnaire-management-view'>
        <div className='questionnaire-list-header'>
          <h1 className='questionnaire-list-title'>{t('questionnairesViewTitle')}</h1>
          {this.renderHeaderButtons()}
        </div>
        <DragDropContext onDragEnd={this.onDragEnd}>
          <Droppable key='question-droppable' droppableId='questionDrop'>
            {droppableContent}
          </Droppable>
        </DragDropContext>
      </div>
    )
  }
}

const mapActionToProps = {
  getQuestionnaires: Questionnaires.getModels,
  deleteQuestionnaire: Questionnaire.deleteModel,
  navigate,
  changeOrder: Questionnaires.changeOrder,
  importQuestionnaires: Questionnaires.import,
  exportQuestionnaires: Questionnaires.exportQuestionnaires,
  pollDownload,
  downloadResource
}

const mapStateToProps = ({questionnaires, authenticatedUser, download}, _ownProps) => {
  return {
    authenticatedUser,
    questionnaires,
    download
  }
}

export default withNamespaces(['common'], {wait: true})(
  connect(
    mapStateToProps,
    mapActionToProps
  )(QuestionnairesView)
)
