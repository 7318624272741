import BaseModel from './BaseModel'
import {setDateFields} from './Common'
import {List} from 'immutable'
import EventTranslation from './EventTranslation'

const constraints = {
  personId: {
    presence: {allowEmpty: false}
  },
  started: {
    presence: true,
    isBeforeAttribute: 'ended',
    isBeforeNow: true
  },
  ended: {
    isBeforeNow: true
  },
  description: {
    presence: {allowEmpty: false}
  }
}

const interestedKeys = ['personId', 'started', 'ended', 'description']

const defaultValues = {
  personId: undefined,
  started: undefined,
  startedDateOnly: false,
  ended: undefined,
  endedDateOnly: false,
  description: undefined,
  updateTime: undefined,
  locked: false,
  severity: undefined,
  causality: undefined,
  outcome: undefined,
  personnelComment: undefined,
  personnelId: undefined,
  ecrfTransferTime: undefined,
  studySiteId: undefined,
  translations: List<EventTranslation>()
}

class AdverseEvent extends BaseModel(defaultValues, interestedKeys, constraints)<AdverseEvent> {
  personId: number
  started: any
  startedDateOnly: any
  ended: any
  endedDateOnly: any
  description: any
  updateTime: any
  locked: boolean
  severity: any
  causality: any
  outcome: any
  personnelComment: any
  personnelId: number
  ecrfTransferTime: any
  studySiteId: number
  translations: List<EventTranslation>

  constructor(js?) {
    super(js)

    if (js) {
      return setDateFields(this, ['started', 'ended', 'ecrfTransferTime'])
        .setListArray(
          [
            {translations: js => new EventTranslation(js)}
          ],
          js
        ) as AdverseEvent
    }
  }

  fromJS(js): AdverseEvent {
    return new AdverseEvent(js)
  }
}

export default AdverseEvent
