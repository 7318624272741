/**
 * Created by Mauritz Untamala on 24/11/15.
 */
import React from 'react'
import {PureComponent} from 'react'
import Toggle from 'react-toggle'
import {Panel} from 'react-bootstrap'

import _ from 'lodash'
import classNames from 'classnames'
import moment from 'moment'
import {formatDate, setTimeNow} from '../../util'

import './TimePicker.less'

interface Props {
  time: any
  dateOnly: boolean
  showDateOnlyToggle: boolean
  showNow: boolean
  showReset: boolean
  disabled: boolean
  onChange: (value) => any
  error: object | string
  t: (key, params?) => any
  minDate?: moment.Moment
  maxDate?: moment.Moment
}

interface State {
  time: moment.Moment
  dateOnly: boolean
}

class TimePicker extends PureComponent<Props, State> {
  constructor(props: Props) {
    super(props)
    this.state = this.getNextState(props)
  }

  componentDidUpdate(prevProps: Props) {
    const {time: prevTime, dateOnly: prevDateOnly} = prevProps
    const {time, dateOnly} = this.props

    if (prevTime !== time || prevDateOnly !== dateOnly) {
      this.setState(this.getNextState(this.props))
    }
  }

  getNextState = (props: Props) => {
    const time = _.isNil(props.time) ? moment() : moment(props.time)

    return {
      time: time.startOf('minute'),
      dateOnly: !!props.dateOnly
    }
  }

  onChange = (newState: State, changeProps?: State) => {
    this.setState(newState, () => this.props.onChange(changeProps || newState))
  }

  onTimeChange = (time) => {
    const {dateOnly} = this.state

    if (_.isNil(time)) {
      return this.onChange({time: moment(), dateOnly}, {time, dateOnly})
    }

    const {minDate, maxDate} = this.props

    if (minDate && minDate.isAfter(time)) {
      time = minDate.clone()
    }

    if (maxDate && maxDate.isBefore(time)) {
      time = maxDate.clone()
    }

    return this.onChange({time, dateOnly})
  }

  add = (field, increment) => {
    const self = this

    return function incrementField(event) {
      event.stopPropagation()
      event.preventDefault()

      self.onTimeChange(self.state.time.add(increment, field))
    }
  }

  toggleDateOnly = () => {
    const dateOnly = !this.state.dateOnly
    const time = this.state.time.clone()
    setTimeNow(time)

    this.onChange({time, dateOnly})
  }

  getDateOnlyToggle = () => {
    if (this.props.showDateOnlyToggle === false) {
      return
    }

    return (
      <div className='time-type'>
        <Toggle
          key='time-type'
          id='time-type'
          defaultChecked={this.state.dateOnly}
          disabled={this.props.disabled}
          onChange={this.toggleDateOnly}
        />
        <span>{this.props.t('toggleDateOnly')}</span>
      </div>
    )
  }

  getNowButton = () => {
    const {showNow, disabled, t} = this.props
    if (!showNow) {
      return
    }

    const setTimeNow = () => this.onTimeChange(moment())

    const className = classNames({
      'cursor-pointer': !disabled
    })

    return (
      <a className={className} onClick={disabled ? null : setTimeNow}>
        {t('now')}
      </a>
    )
  }

  getResetButton = () => {
    const {showReset, disabled, t} = this.props
    if (!showReset) {
      return
    }

    const setTimeUnset = () => this.onTimeChange(null)

    const className = classNames({
      'cursor-pointer': !disabled
    })

    return (
      <a className={className} onClick={disabled ? null : setTimeUnset}>
        {t('reset')}
      </a>
    )
  }

  getTimeControls = () => {
    const dateOnlyToggle = this.getDateOnlyToggle()
    const now = this.getNowButton()
    const reset = this.getResetButton()

    if (!dateOnlyToggle && !now && !reset) {
      return
    }

    return (
      <div className='time-controls'>
        {dateOnlyToggle}
        {now}
        {reset}
      </div>
    )
  }

  getTimeComponent = (className, field, format) => {
    return (
      <div className={className}>
        <button
          disabled={this.props.disabled}
          className='time-picker-arrow-up'
          onClick={this.add(field, 1)}
        />
        {this.state.time.format(format)}
        <button
          disabled={this.props.disabled}
          className='time-picker-arrow-down'
          onClick={this.add(field, -1)}
        />
      </div>
    )
  }

  render() {
    const {time, dateOnly} = this.state
    const {disabled} = this.props

    const daysClasses = classNames({
      'col-xs-3': true,
      'col-xs-offset-3': !!dateOnly
    })

    const hoursAndMinutesClasses = classNames({
      'col-xs-3': true,
      hidden: !!dateOnly
    })

    return (
      <div>
        <div className='row year-picker'>
          <button
            className='year-picker-arrow-prev'
            disabled={disabled}
            onClick={this.add('years', -1)}
          />
          {time.year()}
          <button
            className='year-picker-arrow-next'
            disabled={disabled}
            onClick={this.add('years', 1)}
          />
        </div>
        <div className='row time-picker'>
          {this.getTimeComponent(daysClasses, 'days', 'DD.')}
          {this.getTimeComponent('col-xs-3', 'months', 'MM.')}
          {this.getTimeComponent(hoursAndMinutesClasses, 'hours', 'HH')}
          {this.getTimeComponent(hoursAndMinutesClasses, 'minutes', 'mm')}
        </div>
        {this.getTimeControls()}
      </div>
    )
  }
}

interface AccordionProps {
  id?: string
  wrapperClassName?: string
  label?: string | object
  dateOnly?: boolean
  readonly?: boolean
  showReset?: boolean
  showNow?: boolean
  error?: object | string
  time: any
  showDateOnlyToggle: boolean
  onChange: (value) => any
  t: (key, params?) => any
  minDate?: moment.Moment
  maxDate?: moment.Moment
}

interface AccordionState {
  panelExpanded: boolean
}

export default class TimePickerAccordion extends PureComponent<AccordionProps, AccordionState> {
  constructor(props: AccordionProps) {
    super(props)

    this.state = {
      panelExpanded: false
    }
  }

  getHeaderTime = () => {
    const {time, dateOnly, t} = this.props

    if (time) {
      return formatDate(time, dateOnly)
    }

    return t('timeNotSet')
  }

  getHeader = () => {
    const {label, readonly} = this.props

    const classNameHeader = classNames({
      'time-picker-header': true,
      'with-label': !!label,
      'cursor-not-allowed': readonly
    })

    return (
      <Panel.Heading>
        <Panel.Title componentClass='span' className={classNameHeader} toggle={true}>
          <span className='time-picker-label'>{label ? label : ' '}</span>
          <span className='time-picker-time'>{this.getHeaderTime()}</span>
        </Panel.Title>
      </Panel.Heading>
    )
  }

  getError = () => {
    if (this.props.error) {
      return <div className='error'>{this.props.error}</div>
    }

    return <div/>
  }

  onPanelToggle = () => {
    const {readonly} = this.props

    if (readonly) {
      return
    }

    this.setState({panelExpanded: !this.state.panelExpanded})
  }

  render() {

    const {
      t,
      time,
      dateOnly,
      showDateOnlyToggle,
      showNow,
      showReset,
      onChange,
      error,
      readonly,
      minDate,
      maxDate
    } = this.props

    const {panelExpanded} = this.state

    const wrapperClasses = classNames({
      'time-picker-component': true,
      'input-group': true,
      'panel-group': true,
      'has-error': !!error
    })

    return (
      <div className={wrapperClasses}>
        <Panel eventKey='1' expanded={panelExpanded} onToggle={this.onPanelToggle}>
          {this.getHeader()}
          <Panel.Collapse>
            <Panel.Body>
              <TimePicker
                key='timepicker'
                t={t}
                time={time}
                dateOnly={dateOnly}
                showDateOnlyToggle={showDateOnlyToggle}
                showNow={showNow}
                showReset={showReset}
                onChange={onChange}
                disabled={readonly}
                error={error}
                minDate={minDate}
                maxDate={maxDate}
              />
            </Panel.Body>
          </Panel.Collapse>
        </Panel>
        {this.getError()}
      </div>
    )
  }
}
