export enum ROLES {
  SUBJECT = 'Subject',
  INVESTIGATOR = 'Investigator',
  STUDY_NURSE = 'Study Nurse',
  SITE_MANAGER = 'Site Manager',
  DATA_MANAGER = 'Data Manager',
  SYSTEM_ADMIN = 'System Admin',
  SPONSOR_ADMIN = 'Sponsor Admin',
  MONITOR = 'Monitor',
  LEAD_MONITOR = 'Lead Monitor',
  READ_ONLY = 'Read Only',
  INVITER = 'Inviter'
}

export enum USER_TYPES {
  SUBJECT = 'subject',
  PERSONNEL = 'personnel'
}

export const DATE_FORMAT = 'YYYY-MM-DD'
export const DATE_FORMAT_LONG = 'YYYY-MM-DD HH:mm'
export const DATE_FORMAT_TIMEZONE = 'YYYY-MM-DD HH:mm:ss Z'
export const DATE_DEFAULT_TIMEZONE = 'Europe/Helsinki'
export const UTC_TIMEZONE = 'UTC'

export enum NotificationType {
  INFO = 'info',
  SUCCESS = 'success',
  WARNING = 'warning',
  ERROR = 'error'
}

export const LANG_COLOR_VALID_PUBLISHED = '#ccf6c5'
export const LANG_COLOR_VALID_NOT_PUBLISHED = '#fff8ba'
export const LANG_COLOR_NOT_VALID = '#f6cfc5'

export enum LANGUAGE_STATUS {
  DRAFT = 'draft',
  PUBLISHED = 'published'
}

export const IDLE_TIME_LIMIT = 20 // minutes

export interface SelectOption<ValueType> {
  label: string
  value: ValueType
}
