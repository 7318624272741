import BaseModel from './BaseModel'
import _ from 'lodash'
import {getQuestionFieldName} from '../util'
import {fromJS} from 'immutable'

const constraints = {
  field: {
    presence: {allowEmpty: false}
  },
  type: {
    presence: {allowEmpty: false}
  }
}

const equalsKeys = ['field']

const defaultValues = {
  field: undefined,
  type: undefined,
  options: undefined,
  localization: undefined,
  mandatory: true
}

export enum Type {
  datetime = 'datetime',
  number = 'number',
  select = 'select',
  text = 'text',
  textarea = 'textarea',
  instruction = 'instruction',
  multiselect = 'multiselect'
}

const getLocalizationConstrains = (type, lang, options) => {
  switch (type) {
    case Type.select:
    case Type.multiselect:
      return _.isArray(options)
        ? options.map(key => {
          return {
            [`localization.${lang}.${key}`]: {
              presence: {allowEmpty: false}
            }
          }
        })
        : []
    case Type.instruction:
      return [{
        [`localization.${lang}`]: {
          presence: {allowEmpty: false}
        }
      }]
    case Type.number:
    case Type.datetime:
    case Type.text:
    case Type.textarea:
    default:
      return []
  }
}

const constraintsByLanguage = (type: Type, lang, options?) => {
  if (!lang) {
    return constraints
  }

  const localizationConstraints = getLocalizationConstrains(type, lang, options)

  return _.merge(
    {},
    constraints,
    ...localizationConstraints
  )
}

const numberLocalizationKeys = ['unit', 'label']

export default class QuestionComponent extends BaseModel(defaultValues, equalsKeys, constraints)<QuestionComponent> {
  private field: string
  private type: Type
  private options: any
  private localization: any
  private mandatory: boolean

  constructor(js?: any) {
    super(js)

    let model = this as QuestionComponent

    if (js) {
      if (js.mandatory === undefined) {
        model = model.setMandatory(!this.isInstruction())
      }
      model = model.setOptions(js.options).setLocalization(js.localization)
    }

    return model
  }

  fromJS(js: any): QuestionComponent {
    return new QuestionComponent(js)
  }

  getLocalization() {
    return this.localization && this.localization.toJS()
  }

  setLocalization(localization: any) {
    return this.set('localization', localization ? fromJS(localization) : undefined) as QuestionComponent
  }

  getDefaultLocalization(language: string) {
    return this.isLocalized() ? {[language]: undefined} : undefined
  }

  setDefaultLocalization(language: string) {
    return this.setLocalization(this.getDefaultLocalization(language))
  }

  getOptions() {
    return this.options && this.options.toJS()
  }

  setOptions(options: any) {
    if (this.getType() === Type.number && options) {
      options.format = '-##'

      if (options.decimalDigits > 0) {
        options.format +=
          '.' +
          _.times(options.decimalDigits, () => '0').reduce((accu, value) => accu + '' + value)
      }
    }

    return this.set('options', options ? fromJS(options) : undefined) as QuestionComponent
  }

  getDefaultOptions() {
    switch (this.getType()) {
      case Type.number:
        return {
          min: 0,
          format: '-##.00',
          decimalDigits: 2
        }
      case Type.multiselect:
      case Type.select:
        return []
      default:
        return undefined
    }
  }

  setDefaultOptions() {
    return this.setOptions(this.getDefaultOptions())
  }

  getType(): Type {
    return this.type
  }

  setType(type: Type) {

    const questionComponent = this.set('type', type) as QuestionComponent

    return questionComponent.isInstruction()
      ? questionComponent.setMandatory(false)
      : questionComponent
  }

  getField() {
    return this.field
  }

  setField(field: string) {
    return this.set('field', field) as QuestionComponent
  }

  validateByLanguage(lang: string) {
    return this._validate(constraintsByLanguage(this.getType(), lang, this.getOptions()))
  }

  getLanguageLocalization(lang: string) {
    const localization = this.getLocalization()

    return localization && localization[lang]
  }

  isMandatory() {
    return this.mandatory
  }

  setMandatory(mandatory: boolean) {
    return this.set('mandatory', mandatory) as QuestionComponent
  }

  duplicate(): QuestionComponent {
    return super.duplicate().setField(getQuestionFieldName(this.getType()))
  }

  isInstruction() {
    return this.type === Type.instruction
  }

  isTranslatable() {
    return this.type === Type.textarea || this.type === Type.text
  }

  isLocalized() {
    switch (this.type) {
      case Type.select:
      case Type.multiselect:
      case Type.number:
      case Type.instruction:
        return true
      case Type.text:
      case Type.textarea:
      case Type.datetime:
      default:
        return false
    }
  }

  cleanupTranslations(defaultLanguage: string, additionalLanguages: string[]): QuestionComponent {

    let localization = this.getLocalization()

    if (_.isEmpty(localization) || !this.isLocalized()) {
      return this
    }

    const languages = [defaultLanguage].concat(additionalLanguages || [])

    const resetLocalization = () => {
      return this.setLocalization(languages
        .map(l => ({[l]: undefined}))
        .reduce((accu, val) => Object.assign(accu, val), {}))
    }

    if (_.isEmpty(localization[defaultLanguage])) {
      return resetLocalization()
    }

    const obsoleteLanguages = Object.keys(localization).filter(lang => !_.includes(languages, lang))

    localization = _.omit(localization, obsoleteLanguages)

    const clearAdditionalLanguageKey = (key) => {
      additionalLanguages
        .filter(language => localization[language])
        .forEach(language => localization[language][key] = undefined)
    }

    switch (this.getType()) {
      case Type.select:
      case Type.multiselect:

        const options = this.getOptions()

        if (_.isArray(options)) {
          options
            .filter(key => _.isEmpty(localization[defaultLanguage] && localization[defaultLanguage][key]))
            .forEach(key => clearAdditionalLanguageKey(key))
        }

        break
      case Type.number:

        const defaultLocalization = localization[defaultLanguage]

        if (_.isEmpty(defaultLocalization)
          || !numberLocalizationKeys.some(key => !_.isEmpty(defaultLocalization[key]))) {
          return resetLocalization()
        }

        Object.keys(defaultLocalization)
          .filter(key => _.isEmpty(defaultLocalization[key]))
          .forEach(key => clearAdditionalLanguageKey(key))

        break
      default:
        break
    }

    return this.setLocalization(localization)
  }

  getLanguageTranslations(language: any) {

    const localization = this.getLanguageLocalization(language)
    const type = this.getType()
    const field = this.getField()

    switch (type) {
      case Type.multiselect:
      case Type.select:

        const options = this.getOptions()

        if (_.isEmpty(options)) {
          return []
        }

        return this.getOptions().map(localizationKey => ({
          field,
          type,
          localizationKey,
          value: localization && localization[localizationKey]
        }))

      case Type.number:

        if (!_.isObject(localization)) {
          return []
        }

        return Object.keys(localization).map(localizationKey => ({
          field,
          type,
          localizationKey,
          value: localization[localizationKey]
        }))

      case Type.datetime:
      case Type.text:
      case Type.textarea:
      case Type.instruction:
      default:
        return {
          field,
          type,
          value: localization
        }
    }
  }
}
