import React from 'react'
import {PureComponent} from 'react'
import Input from '../../../components/Input'
import CheckboxFormInput from '../../../components/CheckboxFormInput'
import QuestionPage from './QuestionPage'
import Select from 'react-select'
import _ from 'lodash'
import classNames from 'classnames'
import Toggle from 'react-toggle'
import {stringify} from 'querystring'
import {enumToValues, getFieldError} from '../../../util'
import {List} from 'immutable'
import {SelectOption} from '../../../config/constants'

import Question from '../../../models/Question'
import Questionnaire from '../../../models/Questionnaire'
import User from '../../../models/User'
import StudyModel from '../../../models/Study'
import SiteStudyModel from '../../../models/SiteStudy'

import './Questionnaires.less'
import AccessRights from '../../../models/AccessRights'

interface Props {
  authenticatedUser: User
  page: number
  duplicated: boolean
  siteStudy: SiteStudyModel
  study: StudyModel
  updateModel: (questionnaire) => any
  location: any
  model: Questionnaire
  modelName: string
  accessRights: AccessRights
  t: (key, params?) => any
  showError: boolean
  validationErrors: any
  navigate: (url: string, silent?: boolean) => any
}

interface State {
  pageQuestions: List<Question>
}

type LanguageOption = SelectOption<string>

enum FormFields {
  status = 'status',
  languages = 'languages',
  title = 'title',
  description = 'description',
  period = 'period',
  multipleAnswers = 'multipleAnswers',
  questions = 'questions'
}

const fieldInputs = enumToValues(FormFields)

class QuestionnaireForm extends PureComponent<Props, State> {

  constructor(props) {
    super(props)

    const {model, page} = props
    this.state = {pageQuestions: model.getPageQuestions(page)}
  }

  componentDidUpdate(prevProps, prevState) {

    const {model, page} = this.props
    const {pageQuestions} = this.state

    if (prevState.model !== model || prevProps.page !== page) {
      const latestPageQuestions = model.getPageQuestions(page)

      if (!pageQuestions.equals(latestPageQuestions)) {
        this.setState({pageQuestions: latestPageQuestions})
      }
    }
  }

  updateModel = (model) => this.props.updateModel(model)

  getStudyLanguageOption = (languageCode: string): LanguageOption => {
    const {t} = this.props
    return {
      label: t(`language.${languageCode}`),
      value: languageCode
    }
  }

  getStudyLanguageOptions = () => {
    const {study} = this.props

    return study.languages.toArray().map(this.getStudyLanguageOption)
  }

  onFieldChangeCallback = (field, value) => {
    const {model} = this.props
    const language = model.getDefaultLanguageCode()

    this.updateModel(model.setField(field, value, language))
  }

  renderDefaultLanguageSelectInput = (
    writeAccess: boolean,
    onChange: (option) => any,
    multi?: boolean,
    placeholder?: string
  ) => {
    const {t, model, validationErrors, showError} = this.props
    const field = 'languages'
    const fieldError = getFieldError(
      field,
      validationErrors,
      model.error
    )
    const options = this.getStudyLanguageOptions()
    const defaultLanguage = model.getDefaultLanguage()
    const value = defaultLanguage && options.find((option) => option.value === defaultLanguage.language)
    const wrapperClasses = classNames({
      'default-language': true,
      'has-error': showError && !!fieldError
    })
    const inputKey = `select-questionnaire-${field}`

    return (
      <div key='select-default-language' className={wrapperClasses}>
        <div key='select-default-language-wrapper' id='select-default-language'>
          <Select key={inputKey}
                  id={inputKey}
                  name={inputKey}
                  isDisabled={!writeAccess}
                  value={_.isEmpty(value) ? null : value}
                  isMulti={!!multi}
                  options={options}
                  onChange={onChange}
                  placeholder={placeholder || t('select')}
                  noOptionsMessage={() => t('noOptions')}/>
          <span className='help-block'>
            {fieldError && showError && (<div className='error'>{t(fieldError)}</div>)}
          </span>
        </div>
      </div>
    )
  }

  renderInputField = (field, writeAccess, language, onChange) => {

    const {t, model, validationErrors} = this.props
    const fieldError = getFieldError(
      language ? `${field}.${language}` : field,
      validationErrors,
      model.error
    )
    const wrapperClassName = classNames(['col-xs-7 col-sm-8', field])

    return (
      <Input
        groupClassName='row'
        key={`input_${field}`}
        disabled={!writeAccess}
        label={t(`questionnaire.form.${field}`)}
        labelClassName='col-xs-5 col-sm-4'
        error={fieldError}
        onChange={onChange}
        value={model.getField(field, language)}
        wrapperClassName={wrapperClassName}
        type='text'
        t={t}
      />
    )
  }

  renderNumberInputField = (field, writeAccess, language, onChange) => {

    const {t, model, validationErrors} = this.props
    const fieldError = getFieldError(
      language ? `${field}.${language}` : field,
      validationErrors,
      model.error
    )
    const wrapperClassName = classNames(['col-xs-7 col-sm-8', field])

    return (
      <Input
        groupClassName='row'
        key={`input_${field}`}
        disabled={!writeAccess}
        label={t(`questionnaire.form.${field}`)}
        labelClassName='col-xs-5 col-sm-4'
        error={fieldError}
        onChange={onChange}
        value={model.getField(field, language)}
        wrapperClassName={wrapperClassName}
        type='number'
        t={t}
        min={0}
      />
    )
  }

  renderTextareaField = (field, writeAccess, language, onChange) => {
    const {t, model, validationErrors} = this.props
    const fieldError = getFieldError(
      language ? `${field}.${language}` : field,
      validationErrors,
      model.error
    )

    const wrapperClassName = classNames(['col-xs-7 col-sm-8', field])

    return (
      <Input
        groupClassName='row'
        key={`input_${field}`}
        disabled={!writeAccess}
        label={t(`questionnaire.form.${field}`)}
        labelClassName='col-xs-5 col-sm-4'
        error={fieldError}
        onChange={onChange}
        value={model.getField(field, language)}
        wrapperClassName={wrapperClassName}
        type='textarea'
        t={t}
      />
    )
  }

  renderCheckboxField = (field, writeAccess, language, onChange) => {
    const {t, model} = this.props
    const value = model.getField(field, language)

    return (
      <CheckboxFormInput
        key={`checkbox_${field}`}
        groupClassName='row'
        labelClassName='col-xs-5 col-sm-4'
        inputClassName={classNames(['col-xs-7 col-sm-8', field])}
        field={field}
        label={t(`questionnaire.form.${field}`)}
        value={value}
        disabled={!writeAccess}
        onChange={onChange}
      />
    )
  }

  renderPageButton = (page: number) => {
    const {t, location, navigate, page: currentPage} = this.props

    const onClick = () => {
      const updatedQuery = stringify(_.merge({}, location.query, {page}))
      navigate(`${location.pathname}?${updatedQuery}`)
    }

    const className = classNames(
      'btn btn-default page-btn',
      page === currentPage ? 'active-page' : null
    )

    return (
      <button
        key={`page-${page}`}
        className={className}
        onClick={onClick}>
        {`${t('questionnaire.form.page')} ${page}`}
      </button>
    )
  }

  addPage = () => {
    const {model, location, navigate} = this.props
    const language = model.getDefaultLanguageCode()
    const newQuestionnaire = model.addPage(language)
    this.updateModel(newQuestionnaire)

    const updatedQuery = stringify(
      _.merge({}, location.query, {page: newQuestionnaire.getLastPage()})
    )
    navigate(`${location.pathname}?${updatedQuery}`)
  }

  renderAddPageButton = () => {
    const {t} = this.props

    return (
      <button
        key={`add-page`}
        className='btn btn-default add-page'
        onClick={this.addPage}>
        {t('questionnaire.form.addPage')}
      </button>
    )
  }

  deletePage = () => {
    const {model, page, location, navigate} = this.props
    this.updateModel(model.deletePage(page))

    if (page !== 1) {
      const updatedQuery = stringify(
        _.merge({}, location.query, {page: page - 1})
      )
      navigate(`${location.pathname}?${updatedQuery}`)
    }
  }

  renderDeletePageButton = () => {
    return (
      <i
        key={'delete-page-button'}
        className='fa fa-trash-o pull-right delete-page'
        title={this.props.t('questionnaire.delete')}
        onClick={this.deletePage}/>
    )
  }

  renderPageButtons = () => {
    const {model} = this.props
    const pages = model.getPages()
    const buttons = pages.map(this.renderPageButton)

    buttons.push(this.renderAddPageButton())

    if (pages.length > 0) {
      buttons.push(this.renderDeletePageButton())
    }

    return <div className='page-buttons'>{buttons}</div>
  }

  onQuestionChange = (question) => {

    const {model} = this.props

    this.updateModel(model.updateQuestion(question))
  }

  onQuestionOrderChange = (srcOrder, dstOrder) => {
    const {model, page} = this.props

    this.updateModel(model.reOrderQuestions(page, srcOrder, dstOrder))
  }

  onDeleteQuestion = (question: Question) => {

    const {model} = this.props

    this.updateModel(model.deleteQuestion(question))
  }

  onDuplicateQuestion = (question: Question) => {

    const {model} = this.props

    this.updateModel(model.duplicateQuestion(question))
  }

  addQuestion = () => {

    const {model, page} = this.props
    const language = model.getDefaultLanguageCode()

    this.updateModel(model.addQuestion(language, page))
  }

  addQuestionButton = (t) => {

    const questions = this.props.model.getQuestions()

    if (!questions.isEmpty()) {
      return (
        <div className='add-question-button-container'>
          <button
            className='add-question-button'
            onClick={this.addQuestion}>
            {t('questionnaire.form.addQuestion')}
          </button>
        </div>
      )
    }
  }

  renderQuestions = (language) => {

    const {pageQuestions} = this.state
    const {t} = this.props

    return (
      <div key='questions-container' className='container-fluid questions'>
        <div className='row'>
          <h1>{t('questionnaire.form.questions')}</h1>
        </div>
        <div className='row'>
          {this.renderPageButtons()}
        </div>
        <div className='row'>
          <QuestionPage
            key='questions-page'
            questions={pageQuestions}
            language={language}
            onQuestionOrderChange={this.onQuestionOrderChange}
            onQuestionChange={this.onQuestionChange}
            onDeleteQuestion={this.onDeleteQuestion}
            onDuplicateQuestion={this.onDuplicateQuestion}
            t={t}
          />
        </div>
        {this.addQuestionButton(t)}
      </div>
    )
  }

  onDefaultLanguageChange = (languageOption: LanguageOption) => {
    const {model} = this.props

    this.updateModel(model.setDefaultLanguage(languageOption.value))
  }

  onTitleChange = (value) => this.onFieldChangeCallback('title', value)

  onDescriptionChange = (value) => this.onFieldChangeCallback('description', value)

  onPeriodChange = (value) => this.onFieldChangeCallback('period', value)

  onOrderChange = (value) => this.onFieldChangeCallback('order', value)

  onMultipleAnswersChange = (event) => {
    this.onFieldChangeCallback('multipleAnswers', event.target.checked)
  }

  renderTogglePublishLanguage = () => {

    const {t, model, updateModel} = this.props

    const onChange = () => updateModel(model.toggleStatus())

    return (
      <div key='toggle-publish-wrapper'
        id='toggle-publish'
        className='toggle-publish-language'>
        <label className='control-label' htmlFor='toggle_publish'>
          {t('questionnaire.publishedLanguage')}
        </label>
        <Toggle
          id='toggle_publish'
          key={'toggle_publish'}
          checked={model.isPublished()}
          onChange={onChange}
        />
      </div>
    )
  }

  getFieldInputs = (field) => {

    const {model, modelName, accessRights} = this.props
    const defaultLanguage = model.getDefaultLanguageCode()
    const hasDefaultLanguage = !!defaultLanguage
    const writeAccess = accessRights.hasWriteAccess(modelName, model, field)

    if (writeAccess !== false && writeAccess !== true) {
      return null
    }

    switch (field) {
      case FormFields.languages:
        return this.renderDefaultLanguageSelectInput(
          writeAccess,
          this.onDefaultLanguageChange
        )
      case FormFields.title:
        return this.renderInputField(
          field,
          writeAccess && hasDefaultLanguage,
          defaultLanguage,
          this.onTitleChange
        )
      case FormFields.description:
        return this.renderTextareaField(
          field,
          writeAccess && hasDefaultLanguage,
          defaultLanguage,
          this.onDescriptionChange
        )
      case FormFields.period:
        return this.renderInputField(
          field,
          writeAccess && hasDefaultLanguage,
          defaultLanguage,
          this.onPeriodChange
        )
      case FormFields.multipleAnswers:
        return this.renderCheckboxField(
          field,
          writeAccess && hasDefaultLanguage,
          defaultLanguage,
          this.onMultipleAnswersChange
        )
      case FormFields.questions:
        return this.renderQuestions(defaultLanguage)
      case FormFields.status:
        return this.renderTogglePublishLanguage()
      default:
        throw new Error(`Unsupported field ${field}`)
    }
  }

  render() {

    const rest = _.without(fieldInputs, FormFields.status, FormFields.languages).map(this.getFieldInputs)

    return (
      <div className='questionnaire-form '>
        <div className='form-group status-language-row'>
          {this.getFieldInputs(FormFields.status)}
          {this.getFieldInputs(FormFields.languages)}
        </div>
        {rest}
      </div>
    )
  }
}

export default QuestionnaireForm
