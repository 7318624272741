import _ from 'lodash'
import {stringify} from 'querystring'
import {withNamespaces} from 'react-i18next'
import {connect} from 'react-redux'
import QuestionnaireAnswerComponent from '../../../components/QuestionnaireAnswer'
import {getAccessRights} from '../../../modules/AccessRights'
import {navigate, navigateBack} from '../../../modules/Location'
import QuestionnaireModule from '../../../modules/Questionnaire'
import QuestionnaireAnswerModule from '../../../modules/QuestionnaireAnswer'

const mapActionToProps = {
  getQuestionnaire: QuestionnaireModule.getModel,
  getModel: QuestionnaireAnswerModule.getModel,
  resetModel: QuestionnaireAnswerModule.resetQuestionnaireAnswer,
  saveModel: QuestionnaireAnswerModule.saveModel,
  navigate,
  navigateBack,
  getAccessRights
}

const resolveQuestionnaire = (questionnaires, questionnaire, questionnaireId, questionnaireAnswer) => {

  // For a persisted questionnaire answer we have the questionnaire set in model
  if (questionnaireAnswer.id) {
    return questionnaireAnswer.questionnaire
  }

  if (questionnaire.id !== questionnaireId) {
    return questionnaires.getModelById(questionnaireId) || questionnaire
  }

  return questionnaire
}

const mapStateToProps = (
  {accessRights, authenticatedUser, questionnaire, questionnaires, questionnaireAnswer},
  ownProps
) => {
  const {location, params} = ownProps
  const {qid, id} = params
  let {page} = location.query
  const questionnaireId = parseInt(qid, 10)
  const modelId = id ? parseInt(id, 10) : null
  page = page ? parseInt(page, 10) : 1

  const resolvedQuestionnaire = resolveQuestionnaire(
    questionnaires,
    questionnaire,
    questionnaireId,
    questionnaireAnswer
  )
  const updatedQuery = stringify(_.merge({}, location.query, {page: page - 1}))

  const isSubject = authenticatedUser.isSubject()

  const editUrl = isSubject
    ? `/questionnaires/${resolvedQuestionnaire.id}/answer`
    : '/admin/reports?tab=questionnaires'

  const subjectBackHref = page > 1 ? `${location.pathname}?${updatedQuery}` : '/questionnaires'

  const backHref = isSubject ? subjectBackHref : editUrl

  const forceBackHref = isSubject
  const navigateBackOnSaved = true

  return {
    accessRights,
    authenticatedUser,
    questionnaireId,
    modelId,
    model: questionnaireAnswer,
    questionnaire: resolvedQuestionnaire,
    page,
    wrapperClass: 'questionnaire-answer-view',
    modelName: 'questionnaire-answer',
    backHref,
    forceBackHref,
    editUrl,
    navigateBackOnSaved
  }
}

const mergeProps = (stateProps, dispatchProps, ownProps) => {
  const {questionnaire, authenticatedUser} = stateProps

  return Object.assign({}, ownProps, stateProps, dispatchProps, {
    resetModel: () => dispatchProps.resetModel(questionnaire),
    navigate: authenticatedUser.isPersonnel() ? dispatchProps.navigateBack : dispatchProps.navigate
  })
}

export default withNamespaces(['common'], {wait: true})(connect(
  mapStateToProps,
  mapActionToProps,
  mergeProps,
  {withRef: true}
)(QuestionnaireAnswerComponent))
