import ModelModule from './ModelModule'
import MedicineEvent from '../models/MedicationEvent'
import {bind} from '../util'
import moment from 'moment'

class MedicationEventModule extends ModelModule<MedicineEvent> {
  constructor() {
    super('event/medicine', 'medicationEvent')
    bind(this, this.resetModelWithUserId)
  }

  protected getInitialState = () => new MedicineEvent()

  public resetModelWithUserId(userId) {
    return (dispatch) => dispatch(this.resetModelAction(userId))
  }

  /**
   * @override
   */
  protected getAdditionalActionHandlers() {
    return {
      [this.resetModelType]: (_state, {payload}) =>
        this.getInitialState().set('personId', payload).set('time', moment())
    }
  }
}

export default new MedicationEventModule()
