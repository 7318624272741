import React from 'react'
import {PureComponent} from 'react'
import {Draggable, DraggableProvided, DraggableStateSnapshot} from 'react-beautiful-dnd'
import Questionnaire from '../../../models/Questionnaire'
import QuestionnaireLanguage from '../../../models/QuestionnaireLanguage'
import classNames from 'classnames'

interface Props {
  questionnaire: Questionnaire
  index: number
  selected: boolean
  onSelectionChange: (id: number) => any
  navigate: (url) => any
  deleteQuestionnaire: (model) => any
  t: (key, params?) => any
}

interface State {
}

const draggingItemBackground = '#85bcd3'

const getItemStyle = (isDragging, draggableStyle) => ({
  userSelect: 'none',
  background: isDragging ? draggingItemBackground : undefined,
  ...draggableStyle
})

export default class QuestionnaireListItem extends PureComponent<Props, State> {
  onEditClick = () => {
    const {navigate, questionnaire} = this.props
    navigate(`/admin/questionnaires/${questionnaire.id}`)
  }

  onDeleteClick = () => {
    const {t, questionnaire, deleteQuestionnaire} = this.props
    if (confirm(t(
      'confirm.beforeDeleteQuestionnaire',
      {questionnaireName: questionnaire.getTitle()}
    ))) {
      deleteQuestionnaire(questionnaire)
    }
  }

  onDuplicateClick = () => {
    const {navigate, questionnaire} = this.props
    navigate(`/admin/questionnaires/new?duplicate=true&id=${questionnaire.id}`)
  }

  renderLanguageStatus = (ql: QuestionnaireLanguage) => {

    const {language} = ql
    const resolvedStatus = this.props.questionnaire.getResolvedLanguageStatus(ql)
    const className = classNames([
      'language-status',
      `language-status-${resolvedStatus}`,
      ql.default && 'default-language'
    ])

    return (
      <span key={`${language}-language-status`}
            className={className}>
        {language}
      </span>
    )
  }

  getLanguageStatuses = () => {

    const languages = this.props.questionnaire.getSortedLanguages()

    return languages.map(this.renderLanguageStatus)
  }

  onSelectionChange = () => {

    const {index, onSelectionChange} = this.props

    onSelectionChange(index)
  }

  renderStatusAndActions = () => {

    const {selected, t} = this.props

    return (
      <div className='pull-right item-actions'>
        <div className='language-statuses'>
          {this.getLanguageStatuses()}
        </div>
        <i className='fa fa-copy duplicate'
           title={t('questionnaire.duplicate')}
           onClick={this.onDuplicateClick}/>
        <i className='fa fa-trash-o delete'
           title={t('questionnaire.delete')}
           onClick={this.onDeleteClick}/>
        <input className='select-questionnaire-checkbox'
               type='checkbox'
               checked={selected}
               onChange={this.onSelectionChange}/>
      </div>
    )
  }

  render() {
    const {questionnaire, index} = this.props
    const displayName = questionnaire.getDisplayName()
    const draggableContent = (provided: DraggableProvided, snapshot: DraggableStateSnapshot) => {

      return (
        <li className='list-group-item'
            ref={provided.innerRef}
            {...provided.draggableProps}
            {...provided.dragHandleProps}
            style={getItemStyle(snapshot.isDragging, provided.draggableProps.style)}>
              <span
                className='name'
                title={displayName}
                onClick={this.onEditClick}>
                {displayName}
              </span>
          {this.renderStatusAndActions()}
        </li>
      )
    }

    return (
      <Draggable
        draggableId={questionnaire.id.toString()}
        index={index}>
        {draggableContent}
      </Draggable>
    )
  }
}
