import React from 'react'
import QuestionAnswer from '../../models/QuestionAnswer'
import Questionnaire from '../../models/Questionnaire'
import User from '../../models/User'
import {getFieldError} from '../../util'
import Input from '../Input'
import TimePicker from '../TimePicker'
import QuestionView from '../Question'
import './QuestionnaireAnswer.less'
import ModelView, {BaseProps, BaseState} from '../ModelView'
import QuestionnaireAnswer from '../../models/QuestionnaireAnswer'
import {stringify} from 'querystring'
import classNames from 'classnames'
import _ from 'lodash'

interface Props extends BaseProps<QuestionnaireAnswer> {
  location: any
  page: number
  authenticatedUser: User
  questionnaire: Questionnaire
  questionnaireId?: number
  getQuestionnaire?: (id, queryParams?) => any
  isPreview?: boolean
  onlyContent?: boolean
  forPrinting?: boolean
}

interface State extends BaseState<QuestionnaireAnswer> {
  showError: boolean
  errors: any
}

export default class QuestionnaireAnswerView extends ModelView<QuestionnaireAnswer, Props, State> {

  private readonly myRef: any

  constructor(props: Props) {
    super(props)
    this.myRef = React.createRef()
    const {authenticatedUser, model, page, isPreview} = props

    const errors = authenticatedUser.isPersonnel() && !isPreview
      ? !model.validatePersonnelFields()
      : !model.validatePage(page)
    this.state = Object.assign({}, this.state, {showError: false, errors})
  }

  componentDidMount() {
    super.componentDidMount()
    const {questionnaire, questionnaireId, getQuestionnaire, navigate, modelId, isPreview} = this.props

    if (!questionnaireId) {
      navigate('/questionnaires')
    }

    // Fetch questionnaire if questionnaire answer is new and current questionnaire does not match
    if (!modelId && questionnaire.id !== questionnaireId && !isPreview) {
      getQuestionnaire(questionnaireId)
    }
  }

  componentDidUpdate(prevProps, prevState) {
    super.componentDidUpdate(prevProps, prevState)

    const {questionnaire, page, modelId, resetModel, authenticatedUser, isPreview} = this.props
    const {model, showError} = this.state

    // If is a new answer reset the model once questionnaire is fetched from API.
    // Persisted answers have questionnaire model already set
    if (!modelId && questionnaire.id && prevProps.questionnaire !== questionnaire) {
      resetModel() // Reset call is overloaded in mergeProps to use questionnaire and page for reset
    }

    const getValidationErrors = () => {

      if (prevProps.authenticatedUser !== authenticatedUser || prevState.model !== model) {

        return authenticatedUser.isPersonnel() && !isPreview
          ? model.validatePersonnelFields() : model.validatePage(page)
      }

      return this.state.errors
    }

    const errors = getValidationErrors()

    if (this.state.errors !== errors) {
      this.setState({errors})
    }

    if (showError && !errors) {
      this.setState({showError: false})
    }

    if (prevState.model.isSaving && !model.isSaving) {
      this.onModelSaved()
    }

    if (page !== prevProps.page) {
      this.scrollIntoView()
    }
  }

  hasErrors = () => !!this.state.errors

  scrollIntoView = () => {
    if (this.myRef) {
      this.myRef.current.scrollIntoView({block: 'start', behavior: 'smooth'})
    }
  }

  isSubjectAnswerView = () => {

    const {authenticatedUser, isPreview} = this.props

    return authenticatedUser.isSubject() || isPreview
  }

  getPageNumber = () => {

    const {page} = this.props
    const {model: {questionnaire}} = this.state

    if (!this.isSubjectAnswerView()) {
      return null
    }

    const lastPage = questionnaire.getLastPage()

    return <div className='questionnaire-page-number'>{`${page}/${lastPage}`}</div>
  }

  updateAnswer = updatedAnswer => this.updateModel(this.state.model.addAnswer(updatedAnswer))

  updateTranslation = updatedTranslation => this.updateModel(this.state.model.addTranslation(updatedTranslation))

  renderQuestion = question => {

    const {accessRights, authenticatedUser, t, isPreview, forPrinting} = this.props
    const {model, showError} = this.state
    const {order, page} = question
    const answer = model.getQuestionAnswer(question) || new QuestionAnswer({order, page})
    const translations = model.getQuestionAnswerTranslations(question)
    const writeAccess = forPrinting
      ? false
      : accessRights.hasWriteAccess('questionnaire-answer', model, 'answers') || isPreview
    const resolvedLanguage = model.questionnaire.getResolvedLanguage(authenticatedUser.getLanguage())

    return (
      <QuestionView
        key={`question-${order}-${page}`}
        isPersonnel={authenticatedUser.isPersonnel()}
        enableTranslationFeature={authenticatedUser.isPersonnel() && !isPreview}
        question={question}
        translations={translations}
        answer={answer}
        writeAccess={writeAccess}
        showError={showError}
        onChange={this.updateAnswer}
        onTranslationChange={this.updateTranslation}
        language={resolvedLanguage}
        locked={model.locked}
        t={t}
      />
    )
  }

  renderPageQuestions = () => {

    const {page} = this.props
    const {model: {questionnaire: {questions}}} = this.state

    return questions
      .filter(q => q.page === page)
      .sortBy(q => q.order)
      .map(this.renderQuestion)
      .toArray()
  }

  renderAllQuestions = () => {

    const {model: {questionnaire: {questions}}} = this.state

    return _.sortBy(questions.toArray(), ['page', 'order'])
      .map(this.renderQuestion)
  }

  renderButton = () => {

    const {model} = this.state
    const {page, saveModel, navigate, location, authenticatedUser, isPreview, forPrinting} = this.props
    const {questionnaire} = model

    const onLastPage = authenticatedUser.isSubject() || isPreview ? page === questionnaire.getLastPage() : true

    const onClick = () => {

      if (this.hasErrors()) {
        this.setState({showError: true})
      } else {
        if (onLastPage) {
          if (isPreview) {
            this.setState({showError: true})
          }
          saveModel(model)
        } else {
          const updatedQuery = stringify(_.merge({}, location.query, {page: page + 1}))
          navigate(`${location.pathname}?${updatedQuery}`)
        }
      }
    }

    const getButtonText = () => {

      if (onLastPage) {
        return model.id ? 'button.update' : 'button.send'
      }

      return 'button.next'
    }

    const disabled = forPrinting
      ? true
      : model.isSaving || (authenticatedUser.isSubject() && model.locked)

    return (
      <div className='row'>
        <div className='col-xs-12'>
          <button
            key='action-button'
            className={classNames('btn', 'btn-default', 'btn-lg', 'save-btn')}
            disabled={disabled}
            onClick={onClick}>
            {this.props.t(getButtonText())}
          </button>
        </div>
      </div>
    )
  }

  /**
   * Overridden to stay and show questionnaire answer errors
   *
   * @override
   *
   */
  onModelSaved() {
    const {model} = this.state

    if (model.error) {
      this.setState({showError: true})
      return
    }

    super.onModelSaved()
  }

  /**
   * Overridden to ask after submit invalid questionnaire answer
   *
   * @override
   *
   */
  isFormDirty = () => {
    const {model, modelWithoutChanges} = this.state

    return modelWithoutChanges !== model || model.error
  }

  renderDescription = questionnaire => {
    if (questionnaire.description) {
      return <div className='questionnaire-description'>{questionnaire.getDescription()}</div>
    }
  }

  renderEcrfTransfer = () => {
    const {accessRights, t} = this.props
    const {model} = this.state
    const writeAccess = accessRights.hasWriteAccess('questionnaire-answer', model, 'ecrfTransferTime')

    if (writeAccess === null) {
      return null
    }

    const onEcrfTimeChange = change => this.updateModel(model.set('ecrfTransferTime', change.time))

    return (
      <div key='ecrfTransferTime' className='row'>
        <div className='col-xs-12'>
          <label className='control-label questionnaire-answer-input-label' htmlFor='ecrfTransferTime'>
            {t('ecrfTransferTime')}
          </label>

          <TimePicker
            id='ecrfTransferTime'
            wrapperClassName='col-xs-12 ecrfTransferTime'
            time={model.ecrfTransferTime}
            showDateOnlyToggle={false}
            showNow={true}
            showReset={true}
            onChange={onEcrfTimeChange}
            error={getFieldError('ecrfTransferTime', model.validate(), model.error)}
            t={t}
          />
        </div>
      </div>
    )
  }

  renderPersonnelComment = () => {
    const {accessRights, t} = this.props
    const {model} = this.state
    const writeAccess = accessRights.hasWriteAccess('questionnaire-answer', model, 'personnelComment')

    if (writeAccess === null) {
      return null
    }

    const onFieldChange = value => this.updateModel(model.set('personnelComment', value))

    const field = 'personnelComment'

    return (
      <div className='row personnel-comment'>
        <Input
          key={field}
          labelClassName='questionnaire-answer-input-label'
          groupClassName='col-xs-12'
          type='textarea'
          name={field}
          value={model[field]}
          label={t('personnelComment')}
          onChange={onFieldChange}
          disabled={!writeAccess}
          error={getFieldError(field, model.validate(), model.error)}
          t={t}
        />
      </div>
    )
  }

  renderPersonnelRemarks = () => {
    const {authenticatedUser, isPreview} = this.props

    if (authenticatedUser.isSubject() || isPreview) {
      return null
    }

    return (
      <React.Fragment>
        {this.renderPersonnelComment()}
        {this.renderEcrfTransfer()}
      </React.Fragment>
    )
  }

  isQuestionnaireAnswerAvailable = () => {

    const {questionnaire} = this.state.model

    const {authenticatedUser} = this.props
    const userLanguage = authenticatedUser.language

    const isQuestionnaireAwswerNew = !this.props.modelId
    const questionnaireHasUserLanguage = questionnaire.hasPublishedLanguage(userLanguage)

    if (authenticatedUser.isSubject() && isQuestionnaireAwswerNew && !questionnaireHasUserLanguage) {
      return false
    }

    return true
  }

  getHeading() {

    const {isPreview, authenticatedUser, t} = this.props

    if (!isPreview && authenticatedUser.isPersonnel()) {
      return (
        <div className='row questionnaire-answer-heading'>
          <div className='col-xs-6'>
            {t('subject')}
          </div>
          <div className='col-xs-6'>
            {t('personnel')}
          </div>
        </div>
      )
    }
  }

  getTitle() {

    const {questionnaire, isPreview} = this.props
    const className = classNames({
      'container': !isPreview
    })

    return (
      <div className={className}>
        <h1 className='questionnaire-period'>{questionnaire.getPeriod()}</h1>
      </div>
    )
  }

  getContent() {

    const {questionnaire} = this.state.model
    const {t, isPreview} = this.props

    if (!questionnaire) {
      return undefined
    }

    const contentClassName = classNames({
      'container': !isPreview,
      'answer-content': true
    })

    if (!this.isQuestionnaireAnswerAvailable()) {
      return <div className={contentClassName}>{t('questionnaireAnswer.notAvailable')}</div>
    }

    return (
      <div className={contentClassName}>
        <div className='row questionnaire-info'>
          <div className='col-xs-12'>
            {this.getPageNumber()}
            <div className='questionnaire-title'>{questionnaire.getTitle()}</div>
            {this.renderDescription(questionnaire)}
          </div>
        </div>
        {this.getHeading()}
        {this.isSubjectAnswerView() ? this.renderPageQuestions() : this.renderAllQuestions()}
        {this.renderPersonnelRemarks()}
        {this.renderButton()}
      </div>
    )
  }

  render() {

    const {isPreview, onlyContent, forPrinting} = this.props
    const className = classNames({
      'preview-wrapper': isPreview && !onlyContent
    })

    if (forPrinting) {
      return this.content()
    }

    return (
      <div ref={this.myRef} className={className}>
        {onlyContent ? this.content() : super.render()}
      </div>
    )
  }
}
