import {List} from 'immutable'
import BaseModel from './BaseModel'

const constraints = {
  studyNumber: {
    presence: {allowEmpty: false}
  },
  studyName: {
    presence: {allowEmpty: false}
  },
  projectName: {
    presence: {allowEmpty: false}
  },
  languages: {
    presence: true
  }
}

const interestedKeys = [
  'studyNumber',
  'studyName',
  'projectName',
  'personnelIds',
  'features',
  'languages',
  'isAnimal'
]

const defaultValues = {
  studyNumber: undefined,
  studyName: undefined,
  projectName: undefined,
  personnelIds: List<number>(),
  features: List<any>(),
  languages: List<any>(),
  isAnimal: false
}

export default class Study extends BaseModel(defaultValues, interestedKeys, constraints)<Study> {
  studyNumber: number
  studyName: String
  projectName: string
  personnelIds: List<number>
  features: List<any>
  languages: List<any>
  isAnimal: boolean

  constructor(js?) {
    super(js)

    return this.setListArray(['personnelIds', 'features', 'languages'], js) as Study
  }

  fromJS(js): Study {
    return new Study(js)
  }

  hasFeature(feature) {
    return this.features.includes(feature)
  }

  visibleName() {
    return this.studyName
  }
}
