import React from 'react'
import {Component} from 'react'

import moment from 'moment'
import {DATE_FORMAT_TIMEZONE} from '../../../../config/constants'

import AuditTrail from '../../../../models/AuditTrail'

interface Props {
  audit: AuditTrail
  t?: (key: string, param?: object) => any
}

interface State {}

export default class Details extends Component<Props, State> {
  who = () => {
    const {t, audit} = this.props
    const content = JSON.stringify(audit.who || {}, undefined, 2)

    return (
      <div className='form-group'>
        <label className='col-xs-2'>{t('column.user')}</label>

        <div className='col-xs-10'>
          <div className='panel panel-default'>
            <pre className='json panel-body'>{content}</pre>
          </div>
        </div>
      </div>
    )
  }

  readOnly = (lbl, content) => {
    return (
      <div className='form-group'>
        <label className='col-xs-2'>{lbl}</label>

        <div className='col-xs-10'>
          <div className='form-control'>{content}</div>
        </div>
      </div>
    )
  }

  what = () => {
    const {t, audit} = this.props
    const label = t('column.what')
    const content = audit.what

    return this.readOnly(label, content)
  }

  when = () => {
    const {t, audit} = this.props
    const label = t('column.time')
    const content = audit.time ? moment(audit.time).format(DATE_FORMAT_TIMEZONE) : ''

    return this.readOnly(label, content)
  }

  render() {
    return (
      <div className='form-horizontal'>
        {this.who()}
        {this.what()}
        {this.when()}
      </div>
    )
  }
}
