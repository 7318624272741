import React from 'react'
import {Fragment, PureComponent} from 'react'
import Questionnaires from '../../../modules/Questionnaires'
import {withNamespaces} from 'react-i18next'
import {connect} from 'react-redux'
import {List} from 'immutable'
import Criteria from '../../../models/Criteria'
import SubPageView from '../../../components/SubPageView'
import QuestionnairesModel from '../../../models/Questionnaires'
import QuestionnaireAnswerComponent from '../../../components/QuestionnaireAnswer'
import Questionnaire from '../../../models/Questionnaire'
import QuestionnaireAnswer from '../../../models/QuestionnaireAnswer'
import User from '../../../models/User'
import AccessRights from '../../../models/AccessRights'
import QuestionnaireAnswerModule from '../../../modules/QuestionnaireAnswer'
import {getAccessRights} from '../../../modules/AccessRights'
import {navigate} from '../../../modules/Location'
import ReactToPrint from 'react-to-print'

interface Props {
  location: Location
  tab: string
  page: number
  questionnaireIds: List<number>
  questionnaires: QuestionnairesModel
  authenticatedUser: User
  accessRights: AccessRights
  t: (key, params?) => any
  getQuestionnaires: (queryParams?, reset?) => any
  navigate: (url: string, silent?: boolean) => any
  createPreview: (questionnaire) => any
  getAccessRights: (objectName, object) => any
}

interface State {
  questionnaireAnswers: Map<number, QuestionnaireAnswer>
}

const printPageStyle = 'size: A4;  margin: 2mm;'

class PreviewQuestionnaires extends PureComponent<Props, State> {

  private printRef

  constructor(props: Props) {
    super(props)

    this.state = {
      questionnaireAnswers: new Map<number, QuestionnaireAnswer>()
    }
  }

  componentDidMount() {

    const {questionnaires, getQuestionnaires} = this.props

    if (questionnaires.list.size < 1) {
      getQuestionnaires(undefined, true)
      return
    }

    this.generateQuestionnaireAnswers()
  }

  componentDidUpdate(prevProps: Readonly<Props>, _prevState: Readonly<State>) {
    const {questionnaires} = this.props

    if (questionnaires !== prevProps.questionnaires) {
      this.generateQuestionnaireAnswers()
    }
  }

  setPrintAreaRef = (node) => this.printRef = node

  getPrintAreaRef = () => this.printRef

  generateQuestionnaireAnswers = () => {
    const {questionnaires} = this.props

    let questionnaireAnswers = new Map<number, QuestionnaireAnswer>()

    questionnaires.list.forEach((questionnaire) => {
      questionnaireAnswers.set(questionnaire.id, new QuestionnaireAnswer({questionnaire}))
    })

    this.setState({questionnaireAnswers})
  }

  onPreviewSave = (questionnaireId, questionnaireAnswer) => {

    const updatedQuestionnaireAnswers = this.state.questionnaireAnswers.set(questionnaireId, questionnaireAnswer)

    this.setState({questionnaireAnswers: updatedQuestionnaireAnswers})
  }

  renderQuestionnaire = (questionnaire: Questionnaire) => {

    if (!questionnaire) {
      return
    }

    const answer = this.state.questionnaireAnswers.get(questionnaire.id)

    if (!answer) {
      return
    }

    const {
      authenticatedUser,
      location,
      navigate,
      accessRights,
      t,
      getAccessRights,
      createPreview
    } = this.props
    const pages = questionnaire.getPages()

    const questionnaireContent = pages.map((page) => (
        <QuestionnaireAnswerComponent key={`preview-questionnaire-${questionnaire.id}-page-${page}`}
                                      wrapperClass='questionnaire-preview preview-list-item'
                                      isPreview={true}
                                      onlyContent={true}
                                      forPrinting={true}
                                      model={answer}
                                      modelId={answer.id}
                                      modelName='questionnaire-answer'
                                      questionnaire={questionnaire}
                                      questionnaireId={questionnaire && questionnaire.id}
                                      title={questionnaire && questionnaire.getPeriod()}
                                      location={location}
                                      page={page}
                                      navigate={navigate}
                                      accessRights={accessRights}
                                      getAccessRights={getAccessRights}
                                      authenticatedUser={authenticatedUser}
                                      getModel={() => createPreview(questionnaire)}
                                      editUrl='/admin/questionnaires'
                                      backHref='/admin/questionnaires'
                                      forceBackHref={true}
                                      resetModel={() => createPreview(questionnaire)}
                                      saveModel={this.onPreviewSave.bind(undefined, questionnaire.id)}
                                      t={t}/>
      )
    )

    return (
      <Fragment>
        {questionnaireContent}
        <div className='questionnaire-tail'>
          <span>{t('questionnaire.ending')}</span>
        </div>
      </Fragment>
    )
  }

  renderPrintBtn = () => {

    const {t} = this.props

    return (
      <button className='print-btn'>
        <i className='fa fa-print print'/> {t('questionnaire.print')}
      </button>
    )
  }

  render() {

    const {questionnaires, questionnaireIds, t} = this.props
    const navItems = [{label: 'back', href: '/admin/questionnaires', className: 'back-navigation'}]
    const previewQuestionnaires = questionnaires.getModelsByIds(questionnaireIds)

    return (
      <SubPageView navItems={navItems} t={t}>
        <div className='preview-heading'>
          <ReactToPrint trigger={this.renderPrintBtn}
                        pageStyle={printPageStyle}
                        content={this.getPrintAreaRef}/>
        </div>
        <div ref={this.setPrintAreaRef}
             className='print-area'>
          {previewQuestionnaires.map((q) => this.renderQuestionnaire(q)).toArray()}
        </div>
      </SubPageView>
    )
  }
}

const mapActionToProps = {
  getQuestionnaires: Questionnaires.getModels,
  createPreview: QuestionnaireAnswerModule.resetQuestionnaireAnswer,
  getAccessRights,
  navigate
}

const mapStateToProps = ({accessRights, questionnaires, authenticatedUser}, ownProps) => {

  const {location: {query: {page, tab}}} = ownProps
  const questionnaireIds = Criteria.fromQuery(ownProps.location.query).ids
  const currentPage = page ? parseInt(page, 10) : 1

  return {
    accessRights,
    authenticatedUser,
    questionnaires,
    questionnaireIds,
    page: currentPage,
    tab
  }
}

export default withNamespaces(['common'], {wait: true})(
  connect(
    mapStateToProps,
    mapActionToProps
  )(PreviewQuestionnaires)
)
