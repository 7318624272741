import React from 'react'
import {PureComponent} from 'react'
import Input from '../../../components/Input'
import {getFieldError} from '../../../util'
import _ from 'lodash'

import Question from '../../../models/Question'
import QuestionComponentView from './QuestionComponent'

interface Props {
  question: Question
  language: string
  onChange: (question) => any
  onDeleteQuestion: (question) => any
  onDuplicateQuestion: (question) => any
  t: (key, params?) => any
}

interface State {
}

export default class QuestionView extends PureComponent<Props, State> {

  onFieldChange = (field, value, ignoreLocalization = false) => {

    const {question, language, onChange} = this.props
    onChange(ignoreLocalization ?
      question.set(field, value) :
      question.setField(field, value, language))
  }

  onTitleChange = (value) => {
    this.onFieldChange('title', value)
  }

  renderQuestionField = () => {
    const {question, language, t} = this.props
    const field = 'title'

    const fieldError = getFieldError(
      `${field}.${language}`,
      question.validateByLanguage(language),
      question.error
    )

    return (
      <Input
        groupClassName='input-field col-xs-12'
        key={`input-${field}`}
        error={fieldError}
        onChange={this.onTitleChange}
        value={question.getField(field, language)}
        labelClassName=''
        label={t('questionnaire.form.question')}
        t={t}
      />
    )
  }

  onDeleteQuestion = () => {

    const {question, onDeleteQuestion} = this.props

    onDeleteQuestion(question)
  }

  onDuplicateQuestion = () => {

    const {question, onDuplicateQuestion} = this.props

    onDuplicateQuestion(question)
  }

  onComponentChange = (component) => {

    const {question, onChange} = this.props

    onChange(question.updateComponent(component))
  }

  onComponentDelete = (component) => {

    const {question, onChange} = this.props

    onChange(question.removeComponent(component))
  }

  renderQuestionComponentRow = (component, key) => {

    const {t, language} = this.props

    return (
      <QuestionComponentView
        key={`question-component-${key}`}
        component={component}
        t={t}
        onChange={this.onComponentChange}
        onDelete={this.onComponentDelete}
        language={language}
      />
    )
  }

  renderQuestionComponents = () => {

    const {question} = this.props

    return question.getComponents().map(this.renderQuestionComponentRow).toArray()
  }

  addQuestionComponent = () => {

    const {onChange, question, language} = this.props

    onChange(question.addComponent(language))
  }

  renderAddQuestionComponent = () => {

    const {t} = this.props

    return (
      <div className='add-question-component-button-container'>
        <button
          className='add-question-component-button'
          onClick={this.addQuestionComponent}>
          {t('questionnaire.form.addQuestionComponent')}
        </button>
      </div>
    )
  }

  renderQuestionActions = () => {

    const {t} = this.props

    return (
      <div className='question-actions'>
        <i className='fa fa-copy duplicate'
           title={t('questionnaire.duplicate')}
           onClick={this.onDuplicateQuestion}/>
        <i
          className='fa fa-trash-o delete'
          title={t('questionnaire.delete')}
          onClick={this.onDeleteQuestion}/>
      </div>
    )
  }

  render() {
    const {question: {order}} = this.props

    return (
      <div className='question-form' key={`question-${order}`}>
        <div className='question-top'>
          <h5 className='question-order'>{order}.</h5>
          <div className='draggable-icon'>
            {_.times(3, i => <i key={i}/>)}
          </div>
          {this.renderQuestionActions()}
        </div>
        <div className='title-row'>
          {this.renderQuestionField()}
        </div>
        {this.renderQuestionComponents()}
        {this.renderAddQuestionComponent()}
      </div>
    )
  }
}
